<section class="account-pages login-page d-flex align-items-center">
  <div class="container">
    <div class="account-box">
      <div class="d-flex align-items-end account-box-content">
        <div class="space-30 w-100">
          <div class="form-logo text-center">
            <img src="assets/img/cereus-logo-black.svg" alt="Cereus">
          </div>
          <div class="account-title">
            <h1 class="text-center">Login</h1>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
            <div class="form-group">
              <input type="email" class="form-control form-control-lg" id="emailUser" formControlName="email"
                [ngClass]="{ 'is-invalid': displayMessage['email']}" placeholder="Email">
              <div *ngIf="displayMessage['email']" class="invalid-feedback">
                {{ displayMessage['email'] }}
              </div>
            </div>
            <div class="form-group pw-field  " [ngClass]="{ 'show-pw': !fieldTextType }">
              <input [type]="fieldTextType ? 'text' : 'password'" class="form-control form-control-lg"
                placeholder="Password" id="txtPassword" formControlName="password"
                [ngClass]="{ 'is-invalid': displayMessage['password']}">
              <span class="pw-eye" id="toggle_pwd" (click)="toggleClass()">
                <span class="icon-eye custom-icons eye-icon"></span>
                <span class="icon-eye-slash custom-icons eye-slash-icon"></span>
              </span>
              <div *ngIf="displayMessage['password']" class="invalid-feedback">
                {{ displayMessage['password'] }}
              </div>
            </div>
            <div class="form-group text-end">
              <a class="forgot-link" [routerLink]="['/non-auth/forgot-password']">Forgot Password?</a>
            </div>
            <button type="submit" class="btn btn-primary btn-lg w-100"
              [disabled]="!loginForm.valid && submitted">Login</button>
          </form>
        </div>
      </div>
      <div class="login-form-note">
        <p>Copyright © Cereus Software {{currentYear}} All Rights Reserved.
          Cereus Software, 902 Clint Moore Rd. Suite 144, Boca Raton FL 33487</p>
      </div>
    </div>
  </div>
  <div class="form-bg">
  </div>
</section>