import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreRoleAccessService, Modules } from 'src/app/providers/core-role-access-service';
import { CoreHelperService } from '../../../providers/core-helper/core-helper.service';
import { CollectionService } from '../../../modules/auth/services/collection/collection.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  appVersion = environment.versionText;
  sidebarToggle = false;
  sidebarToggleMobile = false;
  userId: number;
  facilityId: number;
  public Modules;
  title: string = '';
  reportModuleString = '';
  adminModuleString = '';
  resourceModuleString = '';
  userInfo: any;
  activeModuleName: string = "";
  versionText: string = environment.versionText;
  userDomain: number = 0;
  facilityDomainId: number = 1;
  internalDomainId: number = 0;
  constructor(public CoreRoleAcess: CoreRoleAccessService,
    private router: Router,
    public coreHelperService: CoreHelperService,
    public collectionService: CollectionService) { }

  ngOnInit(): void {
    this.userDomain = this.coreHelperService.getUserDomain();
    this.userInfo = this.coreHelperService.getLoggedInUserDetail()
    this.userId = this.coreHelperService.getUserId();
    this.facilityId = this.coreHelperService.getFacilityId();

    this.resourceModuleString = this.moduleName.FacilityMaster + "," + this.moduleName.CarrierMaster + "," + this.moduleName.PatientMaster
      + "," + this.moduleName.FacilityInfo + "," + this.moduleName.DocumentVault

    this.reportModuleString = this.moduleName.CallRecordAnalytics + "," + this.moduleName.ClaimsBilled + "," + this.moduleName.PaymentSummary
      + "," + this.moduleName.PatientStatement + "," + this.moduleName.PaymentDetail + "," + this.moduleName.Aging;

    this.adminModuleString = this.moduleName.UserManagement + "," + this.moduleName.Announcement + "," + this.moduleName.DiagnosticCode
      + "," + this.moduleName.DocumentWorkflow + "," + this.moduleName.Modulemaster + "," + this.moduleName.FacilityDocument + "," +
      this.moduleName.ServiceMaster + "," + this.moduleName.ServiceTypeMaster + "," + this.moduleName.FacilityIdentifier + "," +
      this.moduleName.MessageConfiguration + "," + this.moduleName.TPNMaster + "," + this.moduleName.TenureGoals
      + "," + this.moduleName.Statistics + "," + this.moduleName.PaymentTask + "," + this.moduleName.UploadTypeMaster

    this.coreHelperService.sidebarActiveModuleName.subscribe((moduleName: string) => {
      this.activeModuleName = moduleName;
    });
  }

  public get moduleName(): typeof Modules {
    return Modules;
  }

  isMultipleFaility = () => {
    return this.userInfo.isMultipleFacility;
  }

  setTitle(title: string, isDashboard: boolean = false) {

    // set false to is from back
    this.collectionService.isFromBack = false;

    localStorage.setItem('title', title)
    this.title = title;
    if (isDashboard) {
      this.coreHelperService.setSidebarModuleName("");
      $('#sidebarAccordion .collapse').collapse('hide');
    }
  }

  redirect() {
    if ((this.coreHelperService.getLoggedInUserDomain() === 0) && !(this.coreHelperService.getLoggedInUserDetail()?.roleName == 'Executive')) {
      this.router.navigate(['/auth/dashboard'])
    } else if (this.coreHelperService.getLoggedInUserDomain() === 1) {
      this.router.navigate(['/auth/facility-dashboard'])
    } else if (this.coreHelperService.getLoggedInUserDetail()?.roleName == 'Executive') {
      this.router.navigate(['/auth/executive-dashboard'])
    }
  }

  addBodyClass() {
    var test = document.getElementsByTagName('body')
    this.sidebarToggle = !this.sidebarToggle;

    if (!!this.sidebarToggle) {
      test[0].classList.add('nav-toggle')
    } else {
      test[0].classList.remove('nav-toggle')
    }
  }

  addBodyClassMobile() {
    var test1 = document.getElementsByTagName('body')
    this.sidebarToggleMobile = !this.sidebarToggleMobile;

    if (!!this.sidebarToggleMobile) {
      test1[0].classList.add('nav-toggle-mobile')
    } else {
      test1[0].classList.remove('nav-toggle-mobile')
    }
  }


  getRouterLinkForTab(sectionName, moduleName, tabName) {
    // Router link for tabs in RESOURCE
    if (sectionName == "Resource") {
      if (moduleName == "Facility Master") {
        if (!this.CoreRoleAcess.getTabNewPermission(sectionName, moduleName, tabName) && tabName == "Facilities") {
          return "auth/resource/facility/facility-master";
        } else {
          return "auth/resource/facility/facility-grouping";
        }
      }
    }
    return ""
  }
}
