
<kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
</kendo-grid-pdf-margin>

<ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
    <div class="page-template">
        <div class="header" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">

            <div class="address">
                <div class="for">
                    <h3>Invoice For</h3>
                    <p>
                        Antonio Moreno<br /> Naucalpan de Juárez<br />
                        México D.F., Mexico, 53500
                    </p>
                </div>
                <div class="from">
                    <h3>From</h3>
                    <p>
                        Hanna Moos <br /> Lützowplatz 456<br /> Berlin,
                        Germany, 10785
                    </p>
                </div>
            </div>
        </div>
        <div class="footer">
            <div style="float: right">
                Page {{ pageNum }} of {{ totalPages }}
            </div>
        </div>
    </div>
</ng-template>
<ng-template>ppp</ng-template>
<h2>hello try pdf</h2>
