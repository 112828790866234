import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State, toODataString } from '@progress/kendo-data-query';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
  export class messagesService extends CoreHttpService {
    private messageUrl: string = `${environment.apiUrl}/Message`;

    constructor(
        private _http: HttpClient,
        router: Router,
        spinner: NgxSpinnerService
      ) {
        super(_http, router, spinner);
      }

      getMessageCount(): Observable<any> {
        return this.get(`${this.messageUrl}/FacilityMessageCount?facilityId=0`);
      }

      getMessagesByFacilityId(facilityId: number): Observable<any> {
        return this.get(`${this.messageUrl}/FacilityMessageGetByFacilityId?facilityId=${facilityId}`);
      }

      getMessageGetByMessageId(messageId: number): Observable<any> {
        return this.get(`${this.messageUrl}/FacilityMessageGetByMessageId?facilityId=${messageId}`);
      }

      getMessageThreadByParentId(parentMessageId: number): Observable<any> {
        return this.get(`${this.messageUrl}/FacilityMessageGetThreadByParentId?parentMessageId=${parentMessageId}`);
      }

      toggleMessageRead(messageId: number): Observable<any> {
        return this.post(`${this.messageUrl}/FacilityMessageToggleRead?messageId=${messageId}`, null);
      }

      createNewMessage(model: FormData): Observable<any> {
        return this.postFormData(`${this.messageUrl}/FacilityMessageAdd`, model);
      }

      downloadDocumentByMessageDocumentId(messageDocumentId: number): Observable<any> {
        return this.get(`${this.messageUrl}/FacilityMessageDownloadDocument?messageDocumentId=${messageDocumentId}`); 
      }

      deleteMessage(messageId: number): Observable<any> {
        return this.post(`${this.messageUrl}/FacilityMessageDelete?messageId=${messageId}`, null);
      }
      
  }

  @Injectable({
    providedIn: 'root'
  })
  export abstract class messagesGridService extends BehaviorSubject<GridDataResult> {
    public loading: boolean;
    private messageUrl: string = `${environment.apiUrl}/Message`;
    constructor(private http: HttpClient) {
        super(null);
    }

    public queryMessagesByFacilityId(facilityId: number, state: any): void {
        this.fetchMessagesByFacilityId(facilityId, state).subscribe((x) => super.next(x));
      }
    
    protected fetchMessagesByFacilityId(facilityId: number, state: any): Observable<GridDataResult> {
        const queryStr = `${toODataString(state)}&$count=true`;
        this.loading = true;

        return this.http.get(`${this.messageUrl}/FacilityMessageGetByFacilityId?facilityId=${facilityId}`).pipe(
            map(
            (response) =>
                <GridDataResult>{
                data: response['value'],
                total: parseInt(response['@odata.count'], 10),
                }
            ),
            tap(() => (this.loading = false))
        );
    }

    public queryMessageThreadByParentId(parentMessageId: number, state: any): void {
        this.fetchMessageThreadByParentId(parentMessageId, state).subscribe((x) => super.next(x));
      }
    
    protected fetchMessageThreadByParentId(parentMessageId: number, state: any): Observable<GridDataResult> {
        this.loading = true;
        let httpHeaders: HttpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');

        const token = localStorage.getItem('token');

        if (token && token != "undefined") {
            httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + token);
        }

        return this.http.get(`${this.messageUrl}/FacilityMessageGetThreadByParentId?parentMessageId=${parentMessageId}`, { headers: httpHeaders}).pipe(
            map(
            (response) =>
                <GridDataResult>{
                data: response["apiData"],
                total: parseInt(response["apiData"].length, 10),
                }
            ),
            tap(() => (this.loading = false))
        );
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class messagesMainGridService extends messagesGridService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getMessagesByFacilityId(facilityId: number, state?: State): void {
        this.queryMessagesByFacilityId(facilityId, state);
    }
  }

  @Injectable({
    providedIn: 'root'
  })
  export class messagesThreadGridService extends messagesGridService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getMessageThreadByParentId(parentMessageId: number, state?: State): void {
        this.queryMessageThreadByParentId(parentMessageId, state);
    }
  }