import { Component, Inject, NgZone } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { CoreHelperService } from './providers/core-helper/core-helper.service';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { NotificationHubService } from './providers/notification.service';
import { UserManagementService } from './modules/auth/administration/user-management/user-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  idleState = 'Not started.';
  timedOut = false;
  logoutTimer: string = "";
  interruptTimer: boolean = false;
  logoutInterval: any;

  constructor(
    private idle: Idle,
    public _coreHelper: CoreHelperService,
    private _userManagementService: UserManagementService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router: Router,
    private notification: NotificationHubService,
    private ngZone: NgZone
  ) {
    this.ngZone.runOutsideAngular(() => {
    });
    let vm = this;
    this.oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
      this.timedOut = true;
    });
  }

  ngOnInit() {
    this.idle.setIdle(3600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url != '/') {
          this.idle.watch();
          this.idleState = 'Started.';
          this.idle.onIdleStart.subscribe(() => {
            this.idleState = "You've gone idle!";
            this.timedOut = true;
            this.runLogoutTimer(1);
            let param = {
              userId: this._coreHelper.getUserId(),
              isLogin: 0,
              spMode: 1
            }
            this._userManagementService.addUpdateSignedUser(param).subscribe(res => {
              if (res.apiStatus) {
                // this.getSignedInUsers();
              }
            })
          });
        } else {
          this.timedOut = false;
        }
      }
    });
  }

  ngAfterViewInit() {
  }

  runLogoutTimer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    this.logoutInterval = setInterval(() => {
      if(this.interruptTimer){
        this.interruptTimer = false;
        this.timedOut = false;
        seconds = null;        
        clearInterval(this.logoutInterval);
        return;
      }
      if (seconds != null){
        seconds--;
        if (statSec != 0) statSec--;
        else statSec = 59;
  
        if (statSec < 10) {
          textSec = "0" + statSec;
        } else textSec = statSec;
  
        this.logoutTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
  
        if (seconds == 0) {
          clearInterval(this.logoutInterval);
          this.timedOut = false;
          this.logout();
        }
      }
    }, 1000);
  }

  staySignInUser() {
    this.interruptTimer = true;
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    let param = {
      userId: this._coreHelper.getUserId(),
      isLogin: 1,
      spMode: 1
    }
    if (param.userId != undefined || param.userId != null){
      this._userManagementService.addUpdateSignedUser(param).subscribe(res => {
        if (res.apiStatus) {
          // this.getSignedInUsers();
        }
      })
    }
  }

  public async logout() {
    let param = {
      userId: this._coreHelper.getUserId(),
      isLogin: 0,
      spMode: 1
    }
    if (param.userId != undefined || param.userId != null){
      this._userManagementService.addUpdateSignedUser(param).subscribe(res => {
        // this.getSignedInUsers();
        localStorage.clear();
      })
      await this.oktaAuth.signOut();
    };
  }

  // getSignedInUsers() {
  //   this._userManagementService.getSingedInUserList().subscribe(response => {
  //     let data = [];
  //     response.apiData.forEach((item: any) => {
  //       if (item.deptName.toLowerCase() == this._coreHelper.VOB) {
  //         data.push(item);
  //       }
  //     });
  //     this._userManagementService.getUpdatedOnlineUsers(data);
  //   })
  // }
}
