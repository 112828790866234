<kendo-grid [data]="test" [pageSize]="state.take!" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu">
    <kendo-grid-column field="action" title="Actions" [width]="160" [filterable]="true">
    </kendo-grid-column>
    <kendo-grid-column field="byUser" title="By User" [width]="160" [filterable]="true"></kendo-grid-column>
    <kendo-grid-column field="byDate" title="By Date" [width]="160" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <span>{{dataItem.byDate | localDateTime}}</span>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
