<kendo-dialog [title]="dialogTitle" *ngIf="showCloseOnlyDialog" (close)="onCloseClick()"
    class="kendo-modal modal-md">
    <div class="common-popup-space">
        <div class="row">
            <div class="col-12 col-md-12">
                <div>
                    <p>{{dialogBodyMessage}}</p>
                </div>
            </div>
        </div>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="onCloseClick()"
            class="btn btn-cancel btn-md">{{showCloseOnlyButtonText}}</button>
    </kendo-dialog-actions>
</kendo-dialog>

<!-- Confirmation Dialog Start -->
<kendo-dialog [title]="dialogTitle" *ngIf="showConfirmDialog" (close)="onCloseClick()"
    class="kendo-modal modal-md">
    <div class="common-popup-space">
        <span>{{dialogBodyMessage}}</span>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton type="button" (click)="onYesButtonClick()" class="btn btn-primary btn-md">{{yesButtonText}}</button>
        <button kendoButton (click)="onCloseClick()" class="btn btn-cancel btn-md">{{noButtonText}}</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Confirmation Dialog End -->