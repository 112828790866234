import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CoreHelperService } from "./core-helper/core-helper.service";
import { tabList } from "../common/tabListData";

@Injectable({
    providedIn: 'root'
})
export class CoreRoleAccessService {
    tabList = tabList;
    constructor(private _coreHelperService: CoreHelperService,
        private router: Router) {
    }

    permission: any;
    servicetabPermission: any;

    userMasterDetail: any;
    userSectionList: any;
    userTransList: any;

    setPermission = () => {
        let userInfo = localStorage.getItem('UserDetail');
        if (!this._coreHelperService.isNullOrUndefined(userInfo)) {
            this.permission = JSON.parse(userInfo).userMasterAccessibility
            this.servicetabPermission = JSON.parse(userInfo).userMasterTabAccessibility
        }

        let userInfoNew = localStorage.getItem('UserDetailNew');
        if (!this._coreHelperService.isNullOrUndefined(userInfoNew)) {
            this.userMasterDetail = JSON.parse(userInfoNew)?.userMasterDetail;
            this.userSectionList = JSON.parse(userInfoNew)?.userSectionList;
            this.userTransList = JSON.parse(userInfoNew)?.userTransList;
        }
    }

    public getSectionNewPermission = (sectionName) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.userMasterDetail) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        // this.userMasterDetail.isAllAllowed = 0;
        // check for section all allowed
        if (this.userMasterDetail?.isAllAllowed == 1) {
            return false;
        }

        // this.userMasterDetail = JSON.parse(userInfoNew).userMasterDetail;
        //     this.userSectionList = JSON.parse(userInfoNew).userSectionList;

        if (this._coreHelperService.isNullOrUndefined(sectionName)) {
            return true;
        }

        //alert(2);

        let currentSection = this.userSectionList && this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            return true;
        } else {
            return false
        }
    }


    public getModuleNewPermission = (sectionName, moduleName) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.userMasterDetail) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        // check for section all allowed
        if (this.userMasterDetail?.isAllAllowed == 1) {
            return false;
        }

        if (this._coreHelperService.isNullOrUndefined(sectionName) || this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        // get Current Selected Section
        let currentSection = this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            return true;
        }


        if (currentSection.isAllModuleAllowed === 1) {

            return false;
        }

        // get Current Selected Module
        let currentModule = currentSection.userModuleList.find((f: { moduleName: string; }) => f.moduleName.toLowerCase() == moduleName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return true;
        } else {
            return false
        }

    }

    public getTabNewPermission = (sectionName, moduleName, tabName, tabIndex = 0) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.userMasterDetail) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        // check for section all allowed
        if (this.userMasterDetail.isAllAllowed == 1) {
            this.setTabIndex(sectionName, moduleName, tabName, true)
            return false;
        }

        if (this._coreHelperService.isNullOrUndefined(sectionName) || this._coreHelperService.isNullOrUndefined(moduleName)) {
            this.setTabIndex(sectionName, moduleName, tabName, false)
            return true;
        }

        // GET Current Selected Section
        let currentSection = this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            this.setTabIndex(sectionName, moduleName, tabName, false)
            return true;
        }

        // return if all allowed at section level

        if (currentSection.isAllModuleAllowed == 1) {
            this.setTabIndex(sectionName, moduleName, tabName, true)
            return false;
        }

        // GET Current Selected Module
        let currentModule = currentSection.userModuleList.find((f: { moduleName: string; }) => f.moduleName.toLowerCase() == moduleName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            this.setTabIndex(sectionName, moduleName, tabName, false)
            return true;
        }
        // return if(currentModule.isAllTabAllowed == 0 && currentModule.userModuleTabList.length == 0) ? true : false;

        if (currentModule.isAllTabAllowed == 1) {
            this.setTabIndex(sectionName, moduleName, tabName, true)
            return false;
        }

        // GET Current Selected Tabs
        let currentTabs = currentModule.userModuleTabList.find((f: { tabName: string; }) => f.tabName.toLowerCase() == tabName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentTabs)) {
            this.setTabIndex(sectionName, moduleName, tabName, false)
            return true;
        }

        this.setTabIndex(sectionName, moduleName, tabName, ((currentTabs) ? true : false))
        return (!currentTabs) ? true : false;
    }

    public checkCardNewPermission = (sectionName, moduleName, tabName, cardName) => {
        this.setPermission();

        if (this._coreHelperService.isNullOrUndefined(this.userTransList)) {
            //return this.router.navigate(['']);
        }

        if (this.userMasterDetail.isAllAllowed == 1) {
            return false;
        }
        if (this._coreHelperService.isNullOrUndefined(sectionName) || this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        // GET Current Selected Section
        let currentSection = this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            return true;
        }

        // return if all allowed at section level
        if (currentSection.isAllModuleAllowed == 1) {
            return false;
        }

        // GET Current Selected Module
        let currentModule = currentSection.userModuleList.find((f: { moduleName: string; }) => f.moduleName.toLowerCase() == moduleName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return true;
        }
        // return if(currentModule.isAllTabAllowed == 0 && currentModule.userModuleTabList.length == 0) ? true : false;
        if (currentModule.isAllTabAllowed == 1) {
            return false;
        }

        // GET Current Selected Tabs
        let currentTabs = currentModule.userModuleTabList.find((f: { tabName: string; }) => f.tabName.toLowerCase() == tabName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentTabs)) {
            return true;
        }

        let restrictCard: any = null;

        this.userSectionList.find((sectionObj: any) => {
            if (sectionObj.sectionName.toLowerCase() == sectionName.toLowerCase()) {
                sectionObj.userModuleList.find((moduleObj: any) => {
                    if (moduleObj.moduleName.toLowerCase() == moduleName.toLowerCase()) {
                        if (moduleObj.isAllTabAllowed) {
                            restrictCard = false;
                        }
                        else {
                            moduleObj.userModuleTabList.find((tabNameObj: any) => {
                                if (tabNameObj.tabName.toLowerCase() == tabName.toLowerCase()) {
                                    if (tabNameObj.isAllCardAllowed == 1) {
                                        restrictCard = false;
                                    }
                                }
                            })
                        }
                    }
                })
            }
        });

        if (restrictCard != null && !restrictCard) {
            return false;
        }

        let currentCardObj = this.userTransList.find((f: any) => {
            return f.sectionName == sectionName && f.moduleName == moduleName && f.tabName && f.cardName == cardName;
        });

        if (!this._coreHelperService.isNullOrUndefined(currentCardObj)) {
            if (currentCardObj.isView == 0 && currentCardObj.isEdit == 0) {
                return true;
            } else if (currentCardObj.isView == 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }


    public checkElementNewPermission = (sectionName, moduleName, tabName, elementName, type) => {
        this.setPermission();

        if (this._coreHelperService.isNullOrUndefined(this.userMasterDetail) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        // check for section all allowed
        if (this.userMasterDetail.isAllAllowed == 1) {
            return false;
        }

        if (this._coreHelperService.isNullOrUndefined(this.userTransList)) {
            return true;
        }


        if (this._coreHelperService.isNullOrUndefined(sectionName) || this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        // GET Current Selected Section
        let currentSection = this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            return true;
        }

        // return if all allowed at section level

        if (currentSection.isAllModuleAllowed == 1) {
            return false;

        }

        // GET Current Selected Module
        let currentModule = currentSection.userModuleList.find((f: { moduleName: string; }) => f.moduleName.toLowerCase() == moduleName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return true;
        }

        // return if(currentModule.isAllTabAllowed == 0 && currentModule.userModuleTabList.length == 0) ? true : false;

        if (currentModule.isAllTabAllowed == 1) {
            return false;

        }

        // GET Current Selected Tabs

        let currentTabs = currentModule.userModuleTabList.find((f: { tabName: string; }) => {
            return (f.tabName.toLowerCase()).includes(tabName.toLowerCase()) || f.tabName.toLowerCase() == tabName.toLowerCase();
        });

        if (this._coreHelperService.isNullOrUndefined(currentTabs)) {
            return true;
        }

        if (currentTabs.isAllCardAllowed == 1) {
            return false;
        }

        let restrictElement: any = null;

        this.userTransList.find((elementObj: any) => {
            if (elementObj.sectionName.toLowerCase() == sectionName.toLowerCase() &&
                (elementObj.moduleName.toLowerCase() == moduleName.toLowerCase() || elementObj.moduleName.toLowerCase() == "ALL".toLowerCase()) &&
                (elementObj.tabName.toLowerCase() == tabName.toLowerCase() || tabName == "" || elementObj.tabName.toLowerCase() == "ALL".toLowerCase()) &&
                (elementObj.elementName.toLowerCase() == elementName.toLowerCase() || elementObj.elementName.toLowerCase() == "ALL".toLowerCase()) &&
                (elementObj.elementType.toLowerCase() == type.toLowerCase() || elementObj.elementType.toLowerCase() == "ALL TYPE".toLowerCase())
            ) {
                restrictElement = elementObj.isView == 1 && elementObj.isEdit == 1 ? false : true;
            } else {
                restrictElement = true;
            }
        });

        if (restrictElement != null && !restrictElement) {
            return false;
        }

        let currentelementObj = this.userTransList.find((f: any) => {
            return f.sectionName == sectionName && f.moduleName == moduleName && f.tabName == tabName && f.elementName == elementName
        });

        if (!this._coreHelperService.isNullOrUndefined(currentelementObj)) {
            if ((type.toLowerCase() == 'button' || type.toLowerCase() == 'all') && currentelementObj.isEdit == 0) {
                return true;
            }

            if (currentelementObj.isView == 0 && currentelementObj.isEdit == 0) {
                return true;
            } else if (currentelementObj.isView == 0) {
                return true
            } else {
                return false;
            }
        } else {
            return true;
        }
    }


    public checkAdministrationPermission = (sectionName, moduleName, tabName, elementName, type, text) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.userMasterDetail) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        // check for section all allowed
        if (this.userMasterDetail.isAllAllowed == 1) {
            return false;
        }

        if (this._coreHelperService.isNullOrUndefined(sectionName) || this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        // GET Current Selected Section
        let currentSection = this.userSectionList.find((f: { sectionName: string; }) => f.sectionName.toLowerCase() == sectionName.toLowerCase());
        if (this._coreHelperService.isNullOrUndefined(currentSection)) {
            return true;
        }

        // return if all allowed at section level
        if (currentSection.isAllModuleAllowed == 1 && currentSection.userModuleList.length == 0) {
            return false;
        }

        let currentelementObj = this.userTransList.find((f: any) => {
            return f.sectionName.toLowerCase() == sectionName.toLowerCase() && f.moduleName.toLowerCase() == moduleName.toLowerCase()
        });
        if (!this._coreHelperService.isNullOrUndefined(currentelementObj)) {
            if (text.toLowerCase() == 'View'.toLocaleLowerCase() && currentelementObj.isView == 1) {
                return false;
            }
            if ((type.toLowerCase() == 'button'.toLocaleLowerCase() || type.toLocaleLowerCase() == 'ALL'.toLocaleLowerCase())
                && currentelementObj.isEdit == 0 && currentelementObj.isView == 0) {
                return true;
            }
            else if ((type.toLowerCase() == 'button'.toLocaleLowerCase() || type.toLocaleLowerCase() == 'ALL'.toLocaleLowerCase())
                && currentelementObj.isEdit == 0 && currentelementObj.isView == 1) {
                return true;
            }
            if (currentelementObj.isView == 0 && currentelementObj.isEdit == 0) {
                return true;
            }
            else {
                return false;
            }
        } else {
            return true;
        }
    }

    public getModulePermission = (moduleName) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.permission) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        if (this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        let currentModule = this.permission.find(f => f.moduleName.toLowerCase() == moduleName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return true;
        }

        return currentModule.isModuleAllowed == 0 ? true : false;
    }

    public checkMainModulePermission = (moduleNames) => {
        if (this._coreHelperService.isNullOrUndefined(moduleNames)) {
            return true;
        }

        let arr = moduleNames.split(',')

        let modules = this.permission.filter(f => f.isModuleAllowed).map(m => m.moduleName);
        let finalArr = modules.find(o1 => arr.some(o2 => o1 === o2));

        if (this._coreHelperService.isNullOrUndefined(finalArr)) {
            return true;
        }

        return false;
    }

    checkMenuAccessPermission = (moduleName, menu) => {
        this.setPermission();

        if (this._coreHelperService.isNullOrUndefined(this.permission) && this._coreHelperService.isShowHeader) {
            return this.router.navigate(['']);
        }

        if (this._coreHelperService.isNullOrUndefined(moduleName) && this._coreHelperService.isNullOrUndefined(menu)) {
            return false;
        }

        let currentModule = this.permission.find(f => f.moduleName.toLowerCase() == moduleName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return false;
        }

        if (!currentModule.isModuleAllowed) {
            return false;
        }

        switch (menu) {
            case MenuItems.isAdd:
                return currentModule.isAdd == 0 ? true : false;

            case MenuItems.isDelete:
                return currentModule.isDelete == 0 ? true : false;

            case MenuItems.isEdit:
                return currentModule.isEdit == 0 ? true : false;

            case MenuItems.isNotes:
                return currentModule.isNotes == 0 ? true : false;

            case MenuItems.isNotification:
                return currentModule.isNotification == 0 ? true : false;

            case MenuItems.isStatus:
                return currentModule.isStatus == 0 ? true : false;

            case MenuItems.isView:
                return currentModule.isView == 0 ? true : false;

            default:
                return false;
        }
    }

    //#region Service Module PErmission for tabs
    public getServiceModulePermission = (moduleName) => {
        this.setPermission();
        if (this._coreHelperService.isNullOrUndefined(this.servicetabPermission) && this._coreHelperService.isShowHeader) {
            //return this.router.navigate(['']);
        }

        if (this._coreHelperService.isNullOrUndefined(moduleName)) {
            return true;
        }

        let currentModule = this.servicetabPermission.find(f => f.moduleName.toLowerCase() == moduleName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return true;
        }

        return currentModule.isModuleAllowed == 0 ? true : false;
    }

    public checkServicceMainModulePermission = (moduleNames) => {
        if (this._coreHelperService.isNullOrUndefined(moduleNames)) {
            return true;
        }

        let arr = moduleNames.split(',')

        let modules = this.servicetabPermission.filter(f => f.isModuleAllowed).map(m => m.moduleName);
        let finalArr = modules.find(o1 => arr.some(o2 => o1 === o2));

        if (this._coreHelperService.isNullOrUndefined(finalArr)) {
            return true;
        }

        return false;
    }

    checkServiceMenuAccessPermission = (moduleName, menu) => {
        this.setPermission();

        if (this._coreHelperService.isNullOrUndefined(this.servicetabPermission) && this._coreHelperService.isShowHeader) {
            return this.router.navigate(['']);
        }

        if (this._coreHelperService.isNullOrUndefined(moduleName) && this._coreHelperService.isNullOrUndefined(menu)) {
            return false;
        }

        let currentModule = this.servicetabPermission.find(f => f.moduleName.toLowerCase() == moduleName.toLowerCase());

        if (this._coreHelperService.isNullOrUndefined(currentModule)) {
            return false;
        }

        if (!currentModule.isModuleAllowed) {
            return false;
        }

        switch (menu) {
            case MenuItems.isWorkspace:
                return currentModule.isWorkspace == 0 ? true : false;

            case MenuItems.isProgress:
                return currentModule.isProgress == 0 ? true : false;

            case MenuItems.isScorecard:
                return currentModule.isScorecard == 0 ? true : false;

            case MenuItems.isStatistics:
                return currentModule.isStatistics == 0 ? true : false;

            case MenuItems.isDocuments:
                return currentModule.isDocuments == 0 ? true : false;

            case MenuItems.isTaskManagement:
                return currentModule.isTaskManagement == 0 ? true : false;

            default:
                return false;
        }
    }
    //#endregion




    // ===============================================================================
    // ACTIVE TAB SELECTION
    // ===============================================================================


    public setTabIndex(sectionName, moduleName, tabName, value) {
        this.tabList.forEach((x: any) => {
            if (x.sectionName == sectionName) {
                x.modules.forEach((y: any) => {
                    if (y.name == moduleName && y.tabs != null) {
                        let indexVal = y.tabs.findIndex(z => z[tabName]);
                        y.tabs != null && y.tabs[indexVal]?.hasOwnProperty(tabName) && (y.tabs[indexVal][tabName] = value);
                    }
                })
            }
        })
    }

    public getActiveTabIndex(sectionName, moduleName) {
        let returnIndex = null;
        this.tabList.forEach((x: any) => {
            if (x.sectionName == sectionName) {
                x.modules.forEach((y: any) => {
                    if (y.name == moduleName) {
                        console.log(y.tabs);
                        y.tabs != null && ((y.tabs).forEach((val: any) => {
                            for (var item in val) {
                                if (item != "index" && val[item]) {
                                    returnIndex == null && (returnIndex = val['index']);                                 
                                    console.log("return index" + returnIndex);
                                    break;
                                }
                            }
                        }))
                    }
                })
            }
        })

        return ((returnIndex == null) ? 0 : returnIndex);
    }
    // ===============================================================================
    // END ACTIVE TAB SELECTION
    // ===============================================================================
}

export enum MenuItems {
    isAdd = "isAdd",
    isDelete = "isDelete",
    isEdit = "isEdit",
    isNotes = "isNotes",
    isNotification = "isNotification",
    isStatus = "isStatus",
    isView = "isView",
    isWorkspace = "isWorkspace",
    isProgress = "isProgress",
    isScorecard = "isScorecard",
    isStatistics = "isStatistics",
    isTaskManagement = "isTaskManagement",
    isDocuments = "isDocuments"
}

export enum Modules {
    FacilityMaster = "Facility Master",
    CarrierMaster = "Carrier Master",
    PatientMaster = "Patient Master",
    FacilityInfo = "Facility Info",
    Census = "Census",
    Credentialing = "credentialing",
    VOB = "VOB",
    Authorisation = "UR",
    Billing = "Billing",
    RCM = "RCM",
    MedicalRecord = "Medical Record",
    Payments = "Payments",
    RCMForAdmin = "RCM For Admin",
    CollectionGoal = "Collection Goal",
    DocumentVault = "Document Vault",
    UserManagement = "User Management",
    Announcement = "Announcement",
    DiagnosticCode = "Diagnostic Code",
    Modulemaster = "Module Master",
    DocumentWorkflow = "Document Workflow",
    StatusFlowMaster = "Status Workflow",
    FacilityDocument = "Facility Documents",
    ServiceMaster = "Service Master",
    ServiceTypeMaster = "Service Type Master",
    Taxonomy = "Taxonomy",
    MessageConfiguration = "Message Configuration",
    Department = "Department",
    CallRecordAnalytics = "Call Record Analytics",
    ClaimsBilled = "Claims Billed",
    PaymentSummary = "Payment Summary",
    PaymentDetail = "Payment Detail",
    PatientStatement = "Patient Statement",
    Aging = "Current Payer Breakdown",
    AgingSummary = "AR Aging Summary",
    UrDaily = "UR Daily Authorizations",
    URReview = "UR Upcoming Review",
    PayerPriorityBalance = "Balance By Payer Priority",
    ActivitySummary = "Activity Summary",
    PatientBalance = "Patient Balance Report",
    PayerTypeBreakdown = "Payer Type Breakdown",
    TPN = "TPN",
    RefundRequest = "Refund Request",
    TPNMaster = "Third Party Master",
    Statistics = "Statistics",
    TenureGoal = "Tenure Goal",
    PaymentTask = "Payment Task",
    UploadTypeMaster = "Upload Type Master",
    FacilityIdentifier = "Facility Identifier",
    TenureGoals = "Tenure Goals",
    Permission = "Permission",
}

export enum Sections {
    Dashboard = "Dashboard",
    Resource = "Resource",
    Services = "Services",
    Reports = "Reports",
    Administration = "Administration"
}

// Permission 
export enum SecModules {
    FacilityMaster = "FacilityMaster",
    CarrierMaster = "CarrierMaster",
    PatientMaster = "PatientMaster",
    DocumentVault = "DocumentVault"
}

export enum Tabs {
    Workspace = "Workspace",
    Progress = "Progress",
    Statistics = "Statistics",
    Scorecard = "Scorecard",
    Documents = "Documents",
    TaskManagement = "Task Management",
    FacilityGrouping = "Facility Grouping",
    Facilities = "Facilities",
    Patients = "Patients",
}

export enum Cards {
    Incoming = "Incoming",
    InProgress = "In Progress",
    Issue = "Issue",
    CallBack = "Call Back",
    Completed = "Completed",
    QA = "QA",
    AverageTime = "AverageTime",
    Processings = "Processings",
    AwaitingFacility = "AwaitingFacility",
    AdmissionPercentage = "AdmissionPercentage",
    Performance = "Performance",
    Attendance = "Attendance",
    Engagement = "Engagement",
    Total = "Total",
    Future = "Future",
    DueToday = "Due Today",
    AwaitingInfo = "Awaiting Info",
    DaysAuthorized = "Days Authorized",
    AverageTurnAroundTime = "Average Turn-Around Time",
    Processing = "Processing",
    AwaitingFacilityResponse = "Awaiting Facility Response",
    AvgLengthOfSta = "Avg Length Of Sta",
    SingleCaseAgreements = "Single Case Agreements",
    NegotiationsLogged = "Negotiations Logged",
    ReconsiderationsLogged = "Reconsiderations Logged",
    ClaimsReconsiderationsNegotiated = "Claims / Reconsiderations Negotiated",
    Department = "Department",
    Users = "Users",
    RoleManagement = "Role Management",
    Team = "Team",
    List = "List",
    History = "History"
}

