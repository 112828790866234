<app-sidebar *ngIf="_coreHelper.isShowHeader"></app-sidebar>
<div [ngClass]="{'page-content-main': _coreHelper.isShowHeader == true}">
    <app-header *ngIf="_coreHelper.isShowHeader"></app-header>
    <div [ngClass]="{'page-content': _coreHelper.isShowHeader == true}">
        <router-outlet></router-outlet>
    </div>
</div>
<app-footer *ngIf="_coreHelper.isShowHeader"></app-footer>

<div class="loader-spinner-section">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.9)" name="global" size="medium" type="square-jelly-box" [fullScreen]="true"><img src="../assets/img/loader-white.gif" alt="Loading"></ngx-spinner>
</div>


<!-- Please Confirm Popup Start -->
<kendo-dialog title="Please Confirm" *ngIf="timedOut" (close)="staySignInUser()"
    class="kendo-modal modal-sm text-center">
    <div class="common-popup-alert">
        <h6 class="text-center">Would you like to extend this session ?</h6>
        <h6 class="text-center">Auto sign out in - {{this.logoutTimer}}</h6>
        <kendo-dialog-actions class="d-block text-center">
            <button kendoButton class="btn btn-primary btn-sm" (click)="logout()">Logout</button>
            <button kendoButton class="btn btn-cancel btn-sm" (click)="staySignInUser()">Stay</button>
        </kendo-dialog-actions>
    </div>
</kendo-dialog>
<!-- Please Confirm Popup End -->
