import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { DataStateChangeEvent, PageSizeItem } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { debounceTime, fromEvent, merge, Observable } from 'rxjs';
import { DocumentTypeMasterService } from 'src/app/modules/auth/administration/document-workflow/document-workflow.service';
import { PatientsService } from 'src/app/modules/auth/resource/add-patients-master/patients-master.service';
import { documentsService } from 'src/app/modules/auth/services/document/document.services';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { CoreRoleAccessService, MenuItems, Modules } from 'src/app/providers/core-role-access-service';
import { documentMasterMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { UsernameValidator } from 'src/app/shared/CommonValidation/username.validator';
import { Activities, TopActivityService } from '../../service/topactivity.service';

@Component({
  selector: 'app-document-tab',
  templateUrl: './document-tab.component.html',
  styleUrls: ['./document-tab.component.scss']
})
export class DocumentTabComponent implements OnInit {

  userDetail: any;
  documentList: any[] = [];
  originalDocumentList: any[] = [];
  documentHistory: any[] = [];
  documentsTags: Array<any> = [];
  originalDocumentsTags: Array<any> = [];
  patientList: Array<any> = [];
  originalPatientList: Array<any> = [];
  documentTypeMasterData: any[] = [];
  responsePageDocument: any = 3;
  public documentListData: any;
  public totalDocument = this.documentList.length;
  public skipDocument = 0;
  public pageSize = 5;
  public pageSizes: (PageSizeItem | any)[] = [5, 10, 20, 50];

  public history = false;
  public statusChange = false;
  public addDocument = false;
  paramObj: any = {};
  addDocumentForm: FormGroup | any;
  isDocumentEdit: boolean = false;
  filePath: string = '';
  fileName: string = '';
  public clickedRowItem;
  documentTitle = "Edit Document"

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements!: ElementRef[];
  private genericValidator: GenericValidator;
  displayMessage: { [key: string]: string } = {};
  submitted: boolean = false;
  public formData: FormData = new FormData();
  isViewMode: boolean = false;

  constructor(private _documentService: documentsService,
    public _topActivity: TopActivityService,
    private fb: FormBuilder,
    public _coreHelper: CoreHelperService,
    public _documentTypeMasterService: DocumentTypeMasterService,
    private _patientsService: PatientsService,
    public coreRoleAccess: CoreRoleAccessService) {

    this.addDocumentForm = this.fb.group({
      documentDate: [new Date().toISOString().substring(0, 10)],
      expiryDate: [''],
      documentId: [''],
      documentTypeId: [2, Validators.required],
      categoryId: ['', Validators.required],
      subCategoryId: ['', Validators.required],
      patientId: [0],
      tags: [''],
      statusId: [2, Validators.required],
      fileTypeId: [''],
      internalNotes: [
        null,
        [Validators.maxLength(450), UsernameValidator.cannotContainSpace],
      ],
      externalNotes: [
        null,
        [Validators.maxLength(450), UsernameValidator.cannotContainSpace],
      ],
    });
    this.genericValidator = new GenericValidator(documentMasterMessages);
  }

  ngOnInit(): void {
    this.getDocumentList();
    this.getPatientList();
    this.getTagsList();
    this.getDocumentTypeMasterList()
   // this.userDetail = this._coreHelper.getLoggedInUserDetail();
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    // Merge the blur event observable with the valueChanges observable
    merge(this.addDocumentForm.valueChanges, ...controlBlurs)
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.displayMessage = this.genericValidator.processMessages(
          this.addDocumentForm,
          this.submitted
        );
      });
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  public get module() {
    return Modules;
  }

  public get menuItems() {
    return MenuItems;
  }

  public state: State = {
    skip: 0,
    take: 10,
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }

  //#region Document List
  getDocumentList() {
    let param = { userId: this._coreHelper.getUserId() };
    this._documentService.getDocumentTabList(param).subscribe(
      (Response: any) => {
        if (Response.apiStatus) {
          this.documentList = Response.apiData;
          this.originalDocumentList = Response.apiData;
          this.totalDocument = this.documentList.length;
          this.pageDocumentList();
        }
        this._topActivity.setActivity(Activities.DocumentUpdated);
      },
      (error) => { }
    );
  }

  private pageDocumentList(): void {
    this.documentListData = this.documentList.slice(
      this.skipDocument,
      this.skipDocument + this.pageSize
    );
    this.responsePageDocument =
      this.skipDocument + this.documentListData.length;
  }
  //#endregion

  //#region History

  public historyClose() {
    this.history = false;
  }
  public historyOpen(documentId: number) {
    this.getDocumentHistory(documentId);
    this.history = true;
  }

  getDocumentHistory(documentId: number) {
    let param = { DocumentId: documentId };
    this.documentHistory = [];
    this._documentService.documentHistory(param).subscribe((res) => {
      if (res.apiStatus) {
        this.documentHistory = res.apiData;
      }
    });
  }

  //#endregion

  //#region Add/Update Document
  public addDocumentClose() {
    this.isViewMode = false;
    this.documentTitle = "Edit Document";
    this.addDocument = false;
  }
  public addDocumentOpen(documentId: number, status: string) {
    if (this._coreHelper.isNullOrUndefined(documentId)) {
      documentId = this.clickedRowItem.fileId;
    }
    if(this._coreHelper.isNullOrUndefined(status)){
      status = this.clickedRowItem.status;
    }
    if(!(status.toLowerCase() == 'incoming' && this._coreHelper.isTeamLead())){
      this.isViewMode = true;
      this.documentTitle = "View Document";
      this.addDocumentForm.controls['externalNotes'].disable();
    }
    if(status.toLowerCase() == 'classified' && this._coreHelper.isSpecialist()){
      this.isViewMode = true;
      this.documentTitle = "View Document";
      this.addDocumentForm.controls['externalNotes'].disable();
    }
    this.addDocument = true;
    this.addDocumentForm.reset();
    let param = { userId: 0, documentId: documentId };
    this._documentService.getDocumentList(param).subscribe(
      (Response: any) => {
        let data = Response.apiData[0];
        if (data != null) {
          this.userDetail = data.updatedByName;
          this.documentsTags = this.originalDocumentsTags;
          this.isDocumentEdit = true;
          this.filePath = data.filePath == '' ? null : data.filePath;
          this.fileName = data.fileName == '' ? null : data.fileName;
          this.getCategoryListByDocumentTypeId(data.documentTypeId);
          this.addDocumentForm.controls.documentId.setValue(data.fileId);
          this.formData.set('CreatedBy', data.createdBy); 
          this.addDocumentForm.controls.documentTypeId.setValue(data.documentTypeId);
          this.addDocumentForm.controls.documentDate.setValue(
            new Date(data.createdDate).toISOString().substring(0, 10)
          );

          if (!this._coreHelper.isNullOrUndefined(data.expiryDate)) {
            this.addDocumentForm.controls.expiryDate.setValue(
              new Date(data.expiryDate)
            );
          }

          this.addDocumentForm.controls.categoryId.setValue(data.categoryId);
          this.addDocumentForm.controls.subCategoryId.setValue(
            data.subCategoryId
          );
          this.addDocumentForm.controls.patientId.setValue(data.patientId);
          this.addDocumentForm.controls.tags.setValue(data.tagName?.split(','));
          this.addDocumentForm.controls.statusId.setValue(data.statusId);
          this.addDocumentForm.controls.internalNotes.setValue(
            data.internalNotes
          );
          this.formData.set('InternalNotes', data.internalNotes); 
          this.addDocumentForm.controls['internalNotes'].disable();
          this.addDocumentForm.controls.externalNotes.setValue(
            data.externalNotes
          );
        }
      },
      (error) => { }
    );
  }
  //#endregion

  //#region Status Change
  public statusChangeClose() {
    this.statusChange = false;
  }
  public statusChangeOpen(paramObj: any) {
    if (this._coreHelper.isTeamLead() || this._coreHelper.isSpecialist()) {
      this.paramObj = paramObj;
      if (this.paramObj.statusId == 2 && this._coreHelper.isTeamLead()) {
        this.addDocumentOpen(null,null);
      } else if (this.paramObj.statusId == 3 && this._coreHelper.isSpecialist()) {
        this.statusChange = true;
      }
    }
  }
  //#endregion

  //#region Document Status Change

  DocumentStatusChange() {
    let param = {
      documentId: this.paramObj.id,
      StatusId: this.paramObj.statusId,
      Assigntouser: this._coreHelper.getUserId()
    };
    this._documentService.changeDocumentStatus(param).subscribe((res) => {
      if (res.apiStatus) {
        this.statusChange = false;
        this.getDocumentList();
      }
    });
  }

  //#endregion

  //#region Save Document
  saveDocument(): void {
    let data = this.addDocumentForm.value;

    if (this.addDocumentForm.invalid) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(
        this.addDocumentForm,
        this.submitted
      );
      return;
    }

    this.formData.set('SpMode', '1');
    this.formData.set('DocumentId', data.documentId);
    this.formData.set('DocumentTypeId', data.documentTypeId);
    this.formData.set('DocumentDate', data.documentDate);
    this.formData.set('CategoryId', data.categoryId);
    this.formData.set('SubCategoryId', data.subCategoryId);
    this.formData.set('PatientId', data.patientId);
    this.formData.set('TagName', data.tags ?? '');
    this.formData.set('StatusId', "2");
    this.formData.set('AssignToLead', this._coreHelper.getUserId());
    this.formData.set('AssignToSpecialist', "0");
    this.formData.set('ExpiryDate', !this._coreHelper.isNullOrUndefined(data.expiryDate) ? this._coreHelper.getUtcDate(data.expiryDate).toJSON() : '');
    this.formData.set('ExternalNotes', data.externalNotes ?? '');
    this.formData.set('IsArchive', '1');

    this._documentService.addUpdateDocument(this.formData).subscribe((res) => {

      if (res.apiStatus == true) {
        this.addDocumentClose();
        this.getDocumentList();
        this.addDocumentForm.reset();
        this.fileName = '';
        this.filePath = '';
      }
    });
  }
  //#endregion

  //#region Document tags
  getTagsList() {
    this._documentService.getTagsList().subscribe((res) => {
      if (res.apiStatus) {
        let tags = res.apiData[0].tagName.split(',');
        tags.forEach((item: any) => {
          if (!!item) {
            let data: any = {};
            data.tagName = item.trim();
            data.tagValue = item.trim();
            this.documentsTags.push(data);
          }
        });
        this.originalDocumentsTags = this.documentsTags;
      }
    });
  }

  addTag($event) {
    if (!!$event.target.value) {
      this.documentsTags.push({ 'tagName': $event.target.value, 'tagValue': $event.target.value });
    }
  }

  handleFilter(value) {
    this.patientList = this.originalPatientList.filter(
      (s) => s.patientName.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  //#endregion

  //#region Patient
  getPatientList() {
    let PatientId = { PatientId: 0, byUserId: this._coreHelper.getUserId() };
    this._patientsService.getPatientMasterByPatientId(PatientId).subscribe(
      (Response: any) => {
        if (Response.apiStatus === true) {
          Response.apiData.forEach((item: any) => {
            if (item.patientFacilityId == this._coreHelper.getFacilityId()) {

              let obj = {
                patientId: item.patientMasterId,
                patientName: item.firstName + ' ' + item.lastName,
              };
              this.patientList.push(obj);
            }
          });
          this.originalPatientList = [...this.patientList]
        }
      },
      (error) => { }
    );
  }
  //#endregion

  //#region Document Type Master

  public documentTypeListItems: Array<{ text: string, value: number }> = [
    { text: "Facility", value: 1 },
    { text: "Patient", value: 2 }
  ];

  getDocumentTypeMasterList() {
    let documentTypeMasterId: any = { documentTypeMasterId: 0 };
    this._documentTypeMasterService.getDocumentTypeMasterById(documentTypeMasterId).subscribe(
      (Response: any) => {

        if (Response.apiStatus === true) {
          this.documentTypeMasterData = Response.apiData;
        }
      });
  }

  categoryMasterData: any[] = [];
  subCategoryMasterData: any[] = [];
  getCategoryListByDocumentTypeId(event) {
    this.addDocumentForm.controls.categoryId.reset();
    this.addDocumentForm.controls.subCategoryId.reset();
    this.categoryMasterData = []
    this.subCategoryMasterData = []
    this.documentTypeMasterData.filter((data) => {
      if (event === data.documentTypeId) {
        let categorydataObj = {
          categoryName: data.categoryName,
          documentTypeMasterId: data.documentTypeMasterId
        }
        this.categoryMasterData.push(categorydataObj);

        let subCategoryObj = {
          subCategoryName: data.subCategoryName,
          documentTypeMasterId: data.documentTypeMasterId
        }
        this.subCategoryMasterData.push(subCategoryObj);
      }
    })
  }
  //#endregion
}
