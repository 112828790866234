import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeMasterService extends CoreHttpService {

  private facilityDocumentUrl: string = `${environment.apiUrl}/FacilityDocuments`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }
  
  getDocumentTypeMasterById(params: any) {
    return this.postParams(`${this.facilityDocumentUrl}/GetDocumentTypeMasterById`,"", params);
  }

  AddUpdateDocumentTypeMaster(model: any) {
    return this.post(`${this.facilityDocumentUrl}/AddUpdateDocumentTypeMaster`, model);
  }
}

