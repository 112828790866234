import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as signalR from '@microsoft/signalr';
import { CoreHelperService } from '../providers/core-helper/core-helper.service';
import { UserManagementService } from '../modules/auth/administration/user-management/user-management.service';

@Injectable({
  providedIn: 'root',
})


export class NotificationHubService {
  private connection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.api + '/notification-hub')
    .configureLogging(signalR.LogLevel.None)
    .build();

  public notificationObject = new Subject<number>();
  public storageUpdate = new Subject<boolean>();

  constructor(
    private coreHelperService: CoreHelperService,
    private _userManagementService: UserManagementService
  ) {
    this.connection.on('OnNotification', (res) => {
      if (res.isActive) {
        if (res.userId == this.coreHelperService.getUserId()) {
          let oktaToken = localStorage.getItem('oktaToken');
          let userInfo = this.coreHelperService.getLoggedInUserDetail();
          let userName = this.coreHelperService.isStringEmptyOrWhitespace(userInfo.userName) ? userInfo.emailId : userInfo.userName
          let requestParam: any = {
            userName: userName,
            OktaTokenId: oktaToken,
            isLoginRequest: false,
          };

          this._userManagementService
            .getLoginDetailByUserName(requestParam)
            .subscribe((res) => {
              if (res.apiStatus) {
                localStorage.removeItem('UserDetail');
                localStorage.setItem('UserDetail', JSON.stringify(res.apiData[0]));
                window.location.reload();
              }
            });
        }
      }
      else {
        if (res.userId == this.coreHelperService.getUserId()) {
          this.coreHelperService.callHeaderNotification();
        }
      }
    });
  }

  async StartConnection() {
    try {
      if (this.connection.state == signalR.HubConnectionState.Disconnected) {
        await this.connection.start();
      }
    } catch { }
  }

  async StopConnection() {
    await this.connection.stop();
  }

  OnNotification(): Observable<number> {
    return this.notificationObject.asObservable();
  }
}
