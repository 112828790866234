import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderAuthGuard implements CanActivate {
  constructor(public _coreHelperService: CoreHelperService) { }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      this._coreHelperService.isShowHeader = ( route.routeConfig && !!route.routeConfig.path && !(
      route.routeConfig.path == "login" ||
      route.routeConfig.path == "forgot-password" ||
      route.routeConfig.path == "reset-password" ||
      route.routeConfig.path == "user-verification" ||
      route.routeConfig.path == "user-agreement"
      ))
      let moduleName = !!(route.data['module']) ? route.data['module'] : "";
      this._coreHelperService.setSidebarModuleName(moduleName);
      return true;
  }
}
