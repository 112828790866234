import { NgModule } from "@angular/core";
import { LocalDatePipe, LocalDateTimePipe } from "./local-date-time-pipe";

@NgModule({
  declarations: [LocalDatePipe, LocalDateTimePipe],
  imports: [],
  exports: [LocalDatePipe, LocalDateTimePipe],
  providers: [LocalDatePipe, LocalDateTimePipe]
})
export class CustomDatePipeModule { }
