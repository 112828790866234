import { Component, Input, OnInit } from '@angular/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html'
})
export class HistoryComponent implements OnInit {
  history: boolean;
  
  @Input('data') test: any;
  pageSize: any;
  skip: number;

  constructor() { }

  ngOnInit(): void {
  }

  public state: State = {
    skip: 0,
    take: 10,
  };
  
  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    
  }
  public pageSizes = true;


  public historyClose() {
    this.history = false;
  }

}
