import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cereus-dialog',
  templateUrl: './cereus-dialog.component.html',
  styleUrls: ['./cereus-dialog.component.scss']
})
export class CereusDialogComponent implements OnInit {

  //Common Property
  @Input() dialogTitle: string = "Alert";
  @Input() dialogBodyMessage: string = "No Body";

  //Close Only Dialog Property
  @Input() showCloseOnlyDialog: boolean = false;
  @Input() showCloseOnlyButtonText: string = "Close";

  //Confirm Dialog Property
  @Input() showConfirmDialog: boolean = false;
  @Input() yesButtonText: string = "Yes";
  @Input() noButtonText: string = "No";
  @Output() yesButtonClickEvent = new EventEmitter<void>();
  
  constructor() { }

  ngOnInit(): void {
  }

  onCloseClick(){
    if (this.showCloseOnlyDialog)
      this.showCloseOnlyDialog = false;

    if (this.showConfirmDialog)
      this.showConfirmDialog = false;
  }

  onYesButtonClick(){
    this.yesButtonClickEvent.emit();
  }
}
