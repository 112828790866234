import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';

import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CoreHelperService } from '../core-helper/core-helper.service';
import { NotificationService } from '@progress/kendo-angular-notification';
@Injectable()
export class InterceptorService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private _coreHelper : CoreHelperService) { }
  count = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(!(req.url.includes('ExecutiveDashboard'))){
      this.spinner.show('global')
    }
    
    this.count++;
    return next.handle(req)
      .pipe(tap(
        (event:any) =>
        {
          if(!this._coreHelper.isNullOrUndefined(event) && !this._coreHelper.isNullOrUndefined(event.body))
          {
            if(!event?.body?.apiStatus)
            {
              if(!this._coreHelper.isNullOrUndefined(event?.body?.apiMessage))
              {
                var res = event?.body?.apiMessage?.trim().toLowerCase().replace(/\s/g, "")
                if(res != 'nodatafound!')
                {
                  this.notificationService.show({
                    content: event?.body?.apiMessage,
                    animation: {
                      type: 'fade',
                      duration: 500
                    },
                    type: { style: 'error', icon: true },
                    position: {
                      horizontal: 'center',
                      vertical: 'top'
                    }
                  });
                }
              }
            }
          }

          setTimeout(() => {
            this.dropdownAction();
         }, 1000)

        }






      ), finalize(() => {

        this.count--;

        if (this.count == 0) {
          this.spinner.hide('global')
        }
      })
      );
  }

  dropdownAction = () => {
    var actionCollection = document.getElementsByClassName("dropdown-actions-fixed")
    var arrayActionCollection = Array.from(actionCollection);
    this._coreHelper.dropDownPositionUpdate(arrayActionCollection);
  }

}
