export const tabList = [
    {
        sectionName: "Dashboard",
        modules:[
            {
                name:"Dashboard",
                tabs: null
            }
        ]
    },
    {
        sectionName: "Resource",
        modules:[
            {
                name:"Facility Master",
                tabs:[
                    {
                        "Facility Grouping": true,
                        "index" : 0
                    },
                    {
                        "Facilities": true,
                        "index" : 1
                    }
                ]
            },
            {
                name:"Carrier Master",
                tabs: null
            },
            {
                name:"Patient Master",
                tabs:[
                    {
                        "Patients" : true,
                        "index" : 0
                    },
                    {
                        "Documents" : true,
                        "index" : 1
                    }
                ]
            },
            {
                name:"Document Vault",
                tabs: null
            }
        ]
    },
    {
        sectionName: "Services",
        modules:[
            {
                name:"Census",
                tabs: null
            },
            {
                name:"VOB",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    },
                    {
                        "Documents": true,
                        "index":4
                    },
                    {
                        "Task Management": true,
                        "index":5
                    }

                ]
            },
            {
                name:"UR",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    },
                    {
                        "Documents": true,
                        "index":4
                    },
                    {
                        "Task Management": true,
                        "index":5
                    }

                ]
            },
            {
                name:"Billing",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    },
                    {
                        "Documents": true,
                        "index":4
                    },
                    {
                        "Task Management": true,
                        "index":5
                    }

                ]
            },
            {
                name:"RCM",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    },
                    {
                        "Documents": true,
                        "index":4
                    },
                    {
                        "Task Management": true,
                        "index":5
                    }

                ]
            },
            {
                name:"Medical Record",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    }

                ]
            },
            {
                name:"Payments",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    },
                    {
                        "Scorecard": true,
                        "index":3
                    },
                    {
                        "Documents": true,
                        "index":4
                    },
                    {
                        "Task Management": true,
                        "index":5
                    }

                ]
            },
            {
                name:"TPN",
                tabs:[
                    {
                        "Workspace": true,
                        "index":0
                    },
                    {
                        "Progress": true,
                        "index":1
                    },
                    {
                        "Statistics": true,
                        "index":2
                    }
                ]
            }
        ]
    },
    {
        sectionName: "Reports",
        modules:[
            {
                name:"Call Record Analytics",
                tabs:null
            },
            {
                name:"Claims Billed",
                tabs:null
            },
            {
                name:"Payment Summary",
                tabs:null
            },
            {
                name:"Payment Detail",
                tabs:null
            },
            {
                name:"Patient Statement",
                tabs:null
            },
            {
                name:"Aging",
                tabs:null
            },
            {
                name:"Aging Summary",
                tabs:null
            },
            {
                name:"UR Upcoming Review",
                tabs:null
            },
            {
                name:"UR Daily Authorizations",
                tabs:null
            },
            {
                name:"Activity Summary",
                tabs:null
            },
            {
                name:"Total Balance by Payer Priority",
                tabs:null
            },
            {
                name:"Patient Balance Report",
                tabs:null
            },
        ]
    },
    {
        sectionName: "Administration",
        modules:[
            {
                name:"Announcement",
                tabs: [
                    {
                        "List": true,
                        "index" : 0
                    },
                    {
                        "List": true,
                        "index" : 1
                    }
                ]
            },
            {
                name:"Statistics",
                tabs: [
                    {
                        "Engagement": true,
                        "index": 0
                    },
                    {
                        "Engagement": true,
                        "index": 1
                    }
                ]
            },
            {
                name:"Payment Task",
                tabs: [
                    {
                        "Task Type": true,
                        "index": 0
                    },
                    {
                        "Task Type": true,
                        "index": 1
                    }
                ]
            }
        ]
    }
]