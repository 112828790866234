/*

Development SITE
----------------

OKTA:
https://dev-76664322.okta.com

WEB:
https://devweb.nucleusip.com

API:
https://devapi.nucleusip.com

*/

export const environment = {
  production: false,
  KENDO_UI_LICENSE: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzQxNzUyNDI5fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTc0MTc1MjQyOX0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzQxNzUyNDI5fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNzQxNzUyNDI5fV0sImludGVncml0eSI6ImhMYkltNndSRktOOTlDb3FiNTdKaUVGaGQrdz0iLCJsaWNlbnNlSG9sZGVyIjoibnVjbGVzdWFkbWluQG51Y2xldXNpcC5jb20iLCJpYXQiOjE3MTAyNTI3NjYsImF1ZCI6Im51Y2xlc3VhZG1pbkBudWNsZXVzaXAuY29tIiwidXNlcklkIjoiOTU2MzRhYjEtYWIyYi00NTBmLTk2NTktY2M5MzAyNzJjMmMzIn0.IRBq0RHGTlxkruFGV5pKqwDHPFaJGd3L3yGy6VzhMXn0L3ZiOYa1sQDEMJARoF8_cmfE4odzl4Z3CJ5uzesGbGp66g_AQyLbls0kRlklCd2NkOmcNaGuFw93tX8-A6c0sezQ8R6iyGwaPay7HNFFw1UQCTogNjgSJz3QZnplpD0Ppu4gEMe5fiwH9tX_Y9LPx-BXWAliMpTg6lcW3zAWPI_lpnKO1owmuRZeFUkZ1QWdhYu4WPlyUDBKCovt8x0DjkBVZsDA3AJo_nyWfZwLF3VsBjOJL0uREpUkHvfLbG2VRKwtSaXZp_k6bWRhiDOsjBZ265l-lWSbznaJ4S1kKQ',

  apiUrl: 'https://devapi.nucleusip.com/api',
  api: 'https://devapi.nucleusip.com',
  webUrl : 'https://devweb.nucleusip.com/auth/dashboard',
  userAgreementUrl : 'https://devweb.nucleusip.com/non-auth/user-agreement',
  facilityWebUrl: 'https://devweb.nucleusip.com/auth/facility-dashboard',
  executiveWebUrl: 'https://devweb.nucleusip.com/auth/executive-dashboard',
  enableEnvironment2FA: '0',
  versionText: 'v1.1 Build 04/09/2024',

    /* Okta Configuration  */
  oktaClientId: '0oa91z8euamPgkR3L5d7',
  oktaIssuerDNS: 'https://dev-76664322.okta.com',
  oktaRedirectUri: 'https://devweb.nucleusip.com/login/callback',
  oktaApiScope: ['openid', 'profile', 'email'],
  oktaApiToken: "00xqeLX74uO6QNp9_BfulL-_45_n-U4rVOrDLs4XiB"

};
