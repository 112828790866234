<div class="custom-chart">
    <div class="custom-chart-inner">
<div class="business-value-box">
    <div class="business-bg">
        <div class="min-value">
            <span class="line"></span>
            <span class="value"> {{!!currency? "$": ""}}{{ chartData[0]?.value }}</span>
        </div>
        <div class="max-value">
            <span class="line"></span>
            <span class="value"> {{!!currency? "$": ""}}{{ chartData[1]?.value }}</span>
        </div>
    </div>
    <div *ngIf="!buttons.show" class="legend-box">
        <div class="legend-item"><span class="min-bg"></span>{{ labels?.labelText[0] }}</div>
        <div class="legend-item"><span class="max-bg"></span> {{ labels?.labelText[1] }}</div>
    </div>
    <div *ngIf="buttons.show" class="legend-box">
        <div class="legend-item">
            <button type="button" class="btn btn-primary" (click)="buttonClick()">{{ buttons.buttonLabel }}</button>
        </div>
    </div>
</div>
</div>
</div>
<!-- 
<div class="custom-chart">
    <div class="custom-chart-inner">
        <svg  xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <title>Group 14</title>
            <defs>
                <linearGradient id="linearGradient-1" x1="50%" x2="50%" y1="-30.4542236%"
                    y2="100%">
                    <stop offset="0%" stop-color="#b8f0fe"></stop>
                    <stop offset="100%" stop-color="#4297bf"></stop>
                </linearGradient>
            </defs>
            <g class="layer">
                <title>Layer 1</title>
                <g fill="#000" fill-rule="evenodd" id="Page-1">
                    <g id="Group-14">
                        <rect fill="url(#linearGradient-1)" height="300" id="Rectangle" width="71.56969" x="4.29418"
                            y="0" />
                    </g>
                    <g *ngFor="let item of svgData; let i = index;" id="svg_1">
                        <path
                            d="m0,97.66667l0,-8.16667l4.29418,3.5l0,-0.004l70.85901,-0.001l4.28917,-3.495l0,8.16667l-4.50531,-3.67167l-70.43317,0l-4.50388,3.67167z"
                            fill="#122B45" id="Combined-Shape1" />
                        <text [attr.transform]="'translate('+ (item.value+100) + ' '+ (item.value+100) +');'" style="font: 14px Poppins, sans-serif; white-space: pre; color: white; background-color: #4297BF; padding: 2px 5px;border-radius: 4px;" fill="#000" id="svg_2" x="124.35583" y="210">{{item.key}}</text>
 
                        </g>
                   <g id="svg_3">
                        <path
                            d="m0,210.66667l0,-8.16667l4.29418,3.5l0,-0.004l70.85901,-0.001l4.28917,-3.495l0,8.16667l-4.50531,-3.67167l-70.43317,0l-4.50388,3.67167z"
                            fill="#122B45" id="Combined-Shape2" />
                        <text style="font: 14px Poppins, sans-serif; white-space: pre; color: white; background-color: #4297BF; padding: 2px 5px;border-radius: 4px;" fill="#000" id="svg_4" transform="matrix(0.715697 0 0 1 0 0)" x="122.79448"
                            y="97">test</text>
                    </g>
                </g>

            </g>
            <g>
                <g transform="matrix(1,0,0,1,00,320)">
                    <path style="cursor:pointer;" d="M0 9 L 15 9 15 12 0 12Z" stroke="#b8f0fe" stroke-width="0" fill="#b8f0fe"></path>
                    <g><text style="font:14px Poppins, sans-serif;white-space:pre;" x="21" y="15" stroke="none" fill="#122B45"
                            fill-opacity="1">Current Collected</text></g>
                    <path style="cursor:pointer;" d="M0 0 L 51 0 51 21 0 21Z" stroke="none" fill="#fff" fill-opacity="0"></path>
                </g>
                <g transform="matrix(1,0,0,1,00,340)">
                    <path style="cursor:pointer;" d="M0 9 L 15 9 15 12 0 12Z" stroke="#b8f0fe" stroke-width="0" fill="#b8f0fe"></path>
                    <g><text style="font:14px Poppins, sans-serif;white-space:pre;" x="21" y="15" stroke="none" fill="#122B45"
                            fill-opacity="1">Anticipated</text></g>
                    <path style="cursor:pointer;" d="M0 0 L 51 0 51 21 0 21Z" stroke="none" fill="#fff" fill-opacity="0"></path>
                </g>
              
            </g>
        </svg>
    </div>
</div> -->