<!-- Add Task  Document Popup Start -->
<kendo-grid *ngIf="documentTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="DocumentTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>

</kendo-grid>
<!-- Add Task Document Popup End -->

<!-- Add Task  VOB Popup Start -->
<kendo-grid *ngIf="vobTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize"[skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column> 
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="VOBTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task VOB Popup End -->

<!-- Add Task  Authorisation Popup Start -->
<kendo-grid *ngIf="authorisationTaskManagment === true" (cellClick)="kendoClick($event)"
    (dblclick)="editTaskDetails(null)" [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!"
    [sort]="state.sort!" [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="AuthorisationTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task Authorisation Popup End -->

<!-- Add Task  Billing Popup Start -->
<kendo-grid *ngIf="billingTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="BillingTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task Billing Popup End -->

<!-- Add Task  Collections Popup Start -->
<kendo-grid *ngIf="collectionTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="CollectionTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task Collections Popup End -->

<!-- Add Task  Payments Popup Start -->
<kendo-grid *ngIf="paymentTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="PaymentsTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task Payments Popup End -->

<!-- Add Task  TPN Popup Start -->
<kendo-grid *ngIf="tpnTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="TPNTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task TPN Popup End -->

<!-- Add Task  Refund Popup Start -->
<kendo-grid *ngIf="refundTaskManagment === true" (cellClick)="kendoClick($event)" (dblclick)="editTaskDetails(null)"
    [kendoGridBinding]="taskViewList" [pageSize]= "_coreHelper.pageSize" [skip]="state.skip!" [sort]="state.sort!"
    [sortable]="true" [pageable]="true" filterable="menu" #taskManagementList="kendoGrid">
    <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="160">
    </kendo-grid-column>
    <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
    </kendo-grid-column>
    <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{getHourByMinute(dataItem.taskTimeLine)}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
    </kendo-grid-column>
    <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
    <kendo-grid-column title="Status" [filterable]="true" [width]="180">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="label-with-tooltip">
                <div class="grid-label-btn">
                    <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                </div>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
        [headerClass]="{'text-center': true}">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="dropdown table-dropdown text-center">
                <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed" role="button" id="dropdownMenuLink"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="custom-icons icon-list-menu"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a class="dropdown-item" href="javascript:;" (click)="viewTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-eye"></span>View</a></li>
                    <li><a class="dropdown-item" href="javascript:;" (click)="editTaskDetails(dataItem.taskId)"><span
                                class="custom-icons icon-pencil"></span>Edit</a></li>
                    <li><a *ngIf="dataItem.assignedTo != _coreHelper.getUserId()" class="dropdown-item" href="javascript:;" (click)="showConfirmation(dataItem.taskId)"><span
                                class="custom-icons icon-trash"></span>Delete</a></li>
                    <!-- <li><a class="dropdown-item" href="javascript:;"><span
                                class="custom-icons icon-list-lg"></span>History</a></li> -->
                </ul>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItems]="'Task'" [pagerOf]="'of'"></kendo-grid-messages>
    <kendo-grid-pdf fileName="TaskManagementList.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
        [landscape]="true" [scale]="0.5">
        <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
        </kendo-grid-pdf-margin>
        <!--Export Data AJ-->
        <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                  <div class="address">
                      <div class="for" style="text-align: center; margin-top: 2%;">
                          <h3>Task-Management Data Records </h3>
                      </div>
                  </div>
              </div>
              <div class="footer">
                  <div style="float: right">
                      Page {{ pageNum }} of {{ totalPages }}
                  </div>
              </div>
          </div>
          </ng-template>
          <!--End Export Data -->
          <kendo-grid-column field="taskDate" title="Date" [filterable]="true" [width]="160" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.taskDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskName" title="Type" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="caseId" title="Case#" [filterable]="true" [width]="120">
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="assigneeName" title="Assign To" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [filterable]="true" [width]="180" format="{0:dd/MM/yyyy}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{dataItem.dueDate | date:'MM/dd/yyyy'}}</span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="taskTimeLine" title="Time Utilized" [filterable]="true" [width]="160">
        </kendo-grid-column>
        <kendo-grid-column field="updatedByName" title="Assigned By" [filterable]="true" [width]="180">
        </kendo-grid-column>
        <!-- <kendo-grid-column field="statusName" title="Status" [filterable]="true" [width]="180"></kendo-grid-column> -->
        <kendo-grid-column title="Status" [filterable]="true" [width]="180">
            <ng-template kendoGridCellTemplate let-dataItem>
                <div class="label-with-tooltip">
                    <div class="grid-label-btn">
                        <a class="status-label-btn bg-btn-info">{{dataItem.statusName}}</a>
                    </div>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-pdf>
    <kendo-grid-excel fileName="ReFundTaskManagementList.xlsx" [fetchData]="exportAllTask"></kendo-grid-excel>
</kendo-grid>
<!-- Add Task Refund Popup End -->







<!-- Add Task Document Popup Start -->
<kendo-dialog title="{{taskManagementTitle}}" *ngIf="addTask" (close)="taskModelClose()" class="kendo-modal modal-lg">
    <!-- <kendo-dialog-titlebar>
        <div *ngIf="isViewTask === true">View Task</div>
    </kendo-dialog-titlebar> -->
    <div class="common-popup-space">
        <form [formGroup]="taskManagementForm" (ngSubmit)="addupdateTaskInfo()" autocomplete="off">

            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Date</label>
                        <kendo-datetimepicker [disabled]="true" formControlName="taskDate">
                        </kendo-datetimepicker>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="taskTypeId" class="form-label">Task Type</label>
                        <kendo-dropdownlist [data]="selectTaskType" [textField]="'taskName'" [valueField]="'taskTypeId'"
                            [valuePrimitive]="true" [defaultItem]="{ taskName: 'Select Task Type', taskTypeId: null }"
                            formControlName="taskTypeId" [ngClass]="{ 'is-invalid':   displayMessage['taskTypeId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['taskTypeId']" class="invalid-feedback">
                            {{ displayMessage['taskTypeId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <!-- <pre>{{selectFacility | json}}</pre> -->
                        <label class="form-label">Facility</label>
                        <kendo-dropdownlist [data]="selectFacility" [textField]="'facilityName'"
                            [valueField]="'facilityMasterId'" [valuePrimitive]="true"
                            [defaultItem]="{ facilityName: 'Select Facility', facilityMasterId: null }"
                            formControlName="facilityId" (selectionChange)="checkIsCaseExists($event)"
                            [ngClass]="{ 'is-invalid':   displayMessage['facilityId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['facilityId']" class="invalid-feedback">
                            {{ displayMessage['facilityId'] }}
                        </div>

                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Case#</label>
                        <kendo-dropdownlist [data]="selectCaseId" [textField]="'caseId'" [valueField]="'caseId'"
                            [valuePrimitive]="true" [defaultItem]="{ caseId: 'Select Case Id' }"
                            formControlName="caseId" [ngClass]="{ 'is-invalid':   displayMessage['caseId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['caseId']" class="invalid-feedback">
                            {{ displayMessage['caseId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Assign To</label>
                        <kendo-dropdownlist [data]="selectUser" [textField]="'fullName'" [valueField]="'userId'"
                            [valuePrimitive]="true" [defaultItem]="{ fullName: 'Select User', userId: null }"
                            formControlName="assignedTo" [ngClass]="{ 'is-invalid':   displayMessage['assignedTo']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['assignedTo']" class="invalid-feedback">
                            {{ displayMessage['assignedTo'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label" for="targetStartDate">Due Date</label>
                        <kendo-datepicker calendarType="classic" placeholder="mm/dd/yyyy"
                            [disabledDates]="disabledDates" formControlName="dueDate"
                            [ngClass]="{ 'is-invalid':   displayMessage['dueDate']}"></kendo-datepicker>
                        <div *ngIf="displayMessage['dueDate']" class="invalid-feedback">
                            {{ displayMessage['dueDate'] }}
                        </div>
                    </div>

                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label">Subject</label>
                        <input type="text" class="form-control" formControlName="taskSubject"
                            placeholder="Verification Required"
                            [ngClass]="{ 'is-invalid':   displayMessage['taskSubject']}">
                        <div *ngIf="displayMessage['dueDate']" class="invalid-feedback">
                            {{ displayMessage['dueDate'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label">Details</label>
                        <textarea class="form-control" formControlName="details" placeholder="Description"
                            maxlength="200" [ngClass]="{ 'is-invalid':   displayMessage['details']}"></textarea>
                        <div *ngIf="displayMessage['details']" class="invalid-feedback">
                            {{ displayMessage['details'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Status</label>
                        <kendo-dropdownlist [data]="selectStatus" [textField]="'statusName'" [valueField]="'statusId'"
                            [valuePrimitive]="true" [defaultItem]="{ statusName: 'Select Status', statusId: null }"
                            formControlName="statusId" [ngClass]="{ 'is-invalid':   displayMessage['statusId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['statusId']" class="invalid-feedback">
                            {{ displayMessage['statusId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="form-label">Attachment</label>                        
                        <a [href]="filePath" [download]="fileName" target="_blank"
                                *ngIf="isfileEdit && filePath !=null && fileName != null">
                                {{fileName}}</a>
                        <kendo-fileselect formControlName="filePath">
                            <kendo-fileselect-messages dropFilesHere="Drag & Drop File" select="Browse">
                            </kendo-fileselect-messages>
                        </kendo-fileselect>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="case" class="form-label">Notify Me &nbsp;&nbsp;</label>
                        <input type="checkbox" #notification kendoCheckBox formControlName="notifyMe" />
                    </div>
                </div>
                <!-- <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <div class="common-popup-space">
                            <div class="common-primary-box mb-4">
                                <div class="primary-header">
                                    <div class="box-title">
                                        <h3>History</h3>
                                    </div>

                                </div>
                                <div class="primary-body">
                                    <div class="space-20">
                                        <div class="common-border table-responsive">
                                            <kendo-grid [kendoGridBinding]="taskViewList" [pageSize]="3">
                                                <kendo-grid-column field="SrID" title="Sr. No" let i = index;> </kendo-grid-column>
                                                <kendo-grid-column field="taskSubject" title="Comments">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="updatedBy" title="Updated By">
                                                </kendo-grid-column>
                                                <kendo-grid-column field="updatedDate" title="Updated On">
                                                </kendo-grid-column>
                                            </kendo-grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <kendo-dialog-actions class="d-block text-end">
                <button kendoButton type="submit" *ngIf="!disabled" class="btn btn-primary btn-md">Save</button>
                <button kendoButton (click)="taskModelClose()" class="btn btn-cancel btn-md">Cancel</button>
            </kendo-dialog-actions>
        </form>
    </div>
</kendo-dialog>
<!-- Add Task  Document Popup End -->
