import { Component, ElementRef, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, fromEvent, merge, Observable } from 'rxjs';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { LoginFormMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { UsernameValidator } from 'src/app/shared/CommonValidation/username.validator';
import {
  NotificationService
} from '@progress/kendo-angular-notification';
import { UserManagementService } from '../../auth/administration/user-management/user-management.service';
import { StorageService, StorageKey } from '../../../shared/storage.service';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthTransaction, OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../../auth/administration/permission/permission.service';
import { CoreRoleAccessService } from 'src/app/providers/core-role-access-service';
import { LoginServices } from 'src/app/providers/core-helper/core-helper.classes';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  fieldTextType: boolean = false;
  currentYear: number = new Date().getFullYear();

  constructor(private fb: FormBuilder, 
    private router: Router, 
    public _userManagementService: UserManagementService, 
    public storageService: StorageService,
    private _coreHelper: CoreHelperService,
    private _notificationService: NotificationService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, 
    public authService: OktaAuthStateService,
    private permissionService: PermissionService,
    protected CoreRoleAccess: CoreRoleAccessService) {
    this.genericValidator = new GenericValidator(LoginFormMessages);
  }

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements!: ElementRef[];
  submitted = false;
  displayMessage: { [key: string]: string } = {};
  public genericValidator: GenericValidator;

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this._coreHelper.EmailPattern), UsernameValidator.cannotContainSpace]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15), Validators.pattern(this._coreHelper.Passwordpattern)]],
    })
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    merge(this.loginForm.valueChanges, ...controlBlurs).pipe(
      debounceTime(500)
    ).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.loginForm, this.submitted);
    });
  }

  async login() {
    localStorage.clear();
    let userVerificationOn = environment.enableEnvironment2FA;

    if (this.loginForm.invalid) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(this.loginForm, this.submitted);
      return;
    }

    let logindata = this.loginForm.value;
    let username = logindata.email;
    let password = logindata.password;

    if (userVerificationOn && userVerificationOn === '1') {
      let requestParam: any = { 'userName': username, 'userPassword': password, 'isPinOnly': '0' };
      this._userManagementService.doLoginWithPin(requestParam).subscribe(res => {
        if (res.apiStatus) {
          var userPinInfo = new LoginServices();
          userPinInfo.pinUserId = res.apiData.userId;
          userPinInfo.pinUserName =  requestParam.userName;
          userPinInfo.pinUserPswd = requestParam.userPassword;
          this._coreHelper.SetLoginServiceInformation(userPinInfo);
          this.router.navigate(['/non-auth/user-verification']);
        }
      });
    }
    else {
      await this.oktaAuth.signInWithCredentials({ username, password }).then(res => {
        localStorage.clear();
        this.handleSignInResponse(res)

      }).catch(Exception => {
        this._notificationService.show({
          content: 'Please enter valid username and password.',
          animation: {
            type: 'fade',
            duration: 500
          },
          type: { style: 'error', icon: true },
          position: {
            horizontal: 'center',
            vertical: 'top'
          }
        });
      })
    }
  }

  private handleSignInResponse(transaction: AuthTransaction): void {
    let userName = transaction.user['profile'].login;
    let oktaTokenId = transaction.user['id'];
    localStorage.setItem('oktaToken', oktaTokenId);
    this._coreHelper.passHeaderUserName(transaction.user['profile'].firstName + ' ' + transaction.user['profile'].lastName);
    if (transaction.status !== 'SUCCESS') {
      throw new Error(`We cannot handle the ${transaction.status} status`);
    }
    this.getLoggedInUserDetail(userName, oktaTokenId, transaction)
  }

  getLoggedInUserDetail = (userName, oktaToken, transaction: AuthTransaction) => {
    let requestParam: any = { 'userName': userName, 'OktaTokenId': oktaToken, 'isLoginRequest': true }
    this._userManagementService.getLoginDetailByUserName(requestParam).subscribe(res => {
      if (res.apiStatus) {
        var data = res.apiData[0];
        data.isHideFacility = data?.domainId == 1;
        localStorage.removeItem('UserDetail');
        localStorage.setItem('UserDetail', JSON.stringify(data));
        localStorage.setItem('token', data.token);

        // If successfully Login
        this.permissionService.getLoginDetailsByUserName(requestParam).subscribe((res: any) => {
          if (res.apiStatus) {
            var data = res.apiData;
            data.isHideFacility = data.userMasterDetail.domainId == 1;

            localStorage.removeItem('UserDetailNew');
            localStorage.setItem('UserDetailNew', JSON.stringify(data));

            if (data.userMasterDetail.isAgreed == 0) {
              this.oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, environment.userAgreementUrl);
            }
            else {
              // if dashboard has no access the redirect to default dashabord 
              if (this.CoreRoleAccess.getSectionNewPermission('Dashboard')) {
                console.log('redirect1');
                this.oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, environment.webUrl);

              } else {
                // if logged in user is internal then default dashabord else facility
                if (data.userMasterDetail.domainId === 0) {
                  if (data.userMasterDetail?.roleName === 'Executive') {
                    this.oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, environment.executiveWebUrl);
                  }
                  else {
                    this.oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, environment.webUrl);
                  }
                } else {
                  this.oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, environment.facilityWebUrl);
                }
              }
            }
          }
        })
      }
    })
  }

  getProfile(paramsObj: any) {
    this.submitted = true
    let userId: any = { 'UserId': paramsObj }
    this._userManagementService.getUserList(userId).subscribe((Response: any) => {
      this.storageService.setValue(StorageKey.userId, Response.apiData[0].userId,);
      this.storageService.setValue(StorageKey.FirstName, Response.apiData[0].firstName,);
      this.storageService.setValue(StorageKey.LastName, Response.apiData[0].lastName,);
      this.storageService.setValue(StorageKey.UserName, Response.apiData[0].userName,);
      this.storageService.setValue(StorageKey.profilePath, Response.apiData[0].profilePath,);
    });
  }

  onReset(): void {
    this.submitted = false;
    this.loginForm.reset();
  }

  toggleClass() {
    this.fieldTextType = !this.fieldTextType;
  }
}
