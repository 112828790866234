import { environment } from "src/environments/environment";

export default {
    oidc: {
        clientId: environment.oktaClientId,
        issuer: environment.oktaIssuerDNS,
        redirectUri: environment.oktaRedirectUri,
    },
    resourceServer: {
        messagesUrl: 'http://localhost:8000/api/messages',
    },
};