import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { HeaderAuthGuard } from './common/guards/header-auth.guard';
import { LoginComponent } from './modules/non-auth/login/login.component';
import { AuthGuard } from './common/guards/auth-guards';

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(module => module.AuthModule),
  },
  {
    canActivate: [HeaderAuthGuard,AuthGuard],
    path: 'login',
    loadChildren: () => import('./modules/non-auth/non-auth.module').then(module => module.NonAuthModule),
  },
  {
    //canActivate: [AuthGuard],
    path: 'non-auth',
    loadChildren: () => import('./modules/non-auth/non-auth.module').then(module => module.NonAuthModule),
  },
  {
    path: '',
    canActivate: [HeaderAuthGuard],
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'login/callback',
    canActivate: [HeaderAuthGuard,AuthGuard],
    component: OktaCallbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
