<aside class="sidebar-main">
    <div class="sidebar-header d-flex justify-content-between">
        <a class="logo-box" [routerLink]="this.coreHelperService.getLoggedInUserDomain() == 0 ? ['auth/dashboard'] : ['/auth/facility-dashboard']">
            <img src="../../../../assets/img/cereus-logo.svg" alt="Cereus" class="cereus-logo">
            <img src="../../../../assets/img/cereus-logo-icon.svg" alt="Cereus" class="cereus-icon">
        </a>
        <div class="toggle-icon hidden-mobile-nav" id="sidebarToggle" (click)="addBodyClass()">
            <span class='icon-nav-arrow custom-icons toggle-arrow'>
                <span class='path1'></span>
                <span class='path2'></span>
            </span>
        </div>
        <div class="visible-mobile-nav" (click)="addBodyClassMobile()">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div class="sidebar-content">
        <div class="accordion sidebar-nav" id="sidebarAccordion">
            <div [hidden]="CoreRoleAcess.getSectionNewPermission('Dashboard')" class="accordion-item" (click)="redirect()">
                <h2 class="accordion-header" id="flush-headingOne">
                    <span
                        [ngClass]="{'active-link': ((this.coreHelperService.getLoggedInUserDomain() == 0) || 
                    (this.coreHelperService.getLoggedInUserDomain() == 1) || 
                    (this.coreHelperService.getLoggedInUserDetail()?.roleName == 'Executive')) && (title === 'Dashboard')}"
                        class="accordion-button sidebar-link statick-link" (click)="setTitle('Dashboard', true)">
                        <span class="sidebar-icon" (click)="addBodyClass()">
                            <span class="nav-icon custom-icons icon-dashboard"></span>
                        </span>
                        <span class="sidebar-text"> Dashboard</span>
                    </span>
                </h2>
            </div>
            <div [hidden]="CoreRoleAcess.getSectionNewPermission('Resource')"
                class="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                    <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <span class="sidebar-icon" (click)="addBodyClass()"><span
                                class="nav-icon custom-icons icon-briefcase"></span></span> <span
                            class="sidebar-text">Resource</span>
                    </span>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                    data-bs-parent="#sidebarAccordion">
                    <div class="accordion-body">
                        <ul class="sub-nav">
                            <li *ngIf="(this.coreHelperService.getLoggedInUserDomain() == 0 || (this.coreHelperService.getLoggedInUserDomain() == 1 && isMultipleFaility() == 1))">
                                <a class="k-link" [routerLink]="[getRouterLinkForTab('Resource', 'Facility Master', 'Facilities')]"
                                (click)="setTitle(moduleName.FacilityMaster)"
                                [hidden]="CoreRoleAcess.getModuleNewPermission('Resource', moduleName.FacilityMaster)">{{moduleName.FacilityMaster}} </a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/resource/facility/carriers-master']" [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.CarrierMaster}"
                                    (click)="setTitle(moduleName.CarrierMaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Resource', moduleName.CarrierMaster)">
                                    {{moduleName.CarrierMaster}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/resource/facility/patients']" [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.PatientMaster }"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle(moduleName.PatientMaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Resource', moduleName.PatientMaster)">{{moduleName.PatientMaster}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/document']"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle(moduleName.DocumentVault)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Resource', moduleName.DocumentVault)">{{moduleName.DocumentVault}}</a>
                            </li>
                            <li *ngIf="(this.coreHelperService.getLoggedInUserDomain() == 1 && isMultipleFaility() == 0)"><a class="k-link" [routerLink]="['auth/resource/facility/view-facility']"
                                    [queryParams]="{facilityId:  this.facilityId}" routerLinkActive="active-link"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="setTitle(moduleName.FacilityInfo)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Resource', moduleName.FacilityInfo)">{{moduleName.FacilityInfo}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item" [hidden]="CoreRoleAcess.getSectionNewPermission('Services')">
                <h2 class="accordion-header" id="flush-headingThree">
                    <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <span class="sidebar-icon" (click)="addBodyClass()"><span
                                class="nav-icon custom-icons icon-briefcase-medical"></span></span> <span
                            class="sidebar-text">Services</span>
                    </span>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                    data-bs-parent="#sidebarAccordion">
                    <div class="accordion-body">
                        <ul class="sub-nav">
                            <li><a class="k-link" [routerLink]="['auth/services/census']" routerLinkActive="active-link"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.Census }"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', moduleName.Census)"
                                    (click)="setTitle('Census')">{{moduleName.Census}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/vob']"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.VOB}"
                                    routerLinkActive="active-link"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'VOB')"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('VOB')">{{moduleName.VOB}}</a></li>
                            <li><a class="k-link" [routerLink]="['auth/services/authorization']"
                                [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.Authorisation }"
                                [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'UR')"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('Authorization')">UR</a></li>
                            <li><a class="k-link" [routerLink]="['auth/services/billing']"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.Billing }"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'Billing')"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('Billing')">{{moduleName.Billing}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/collection']"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.RCM }"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'RCM')"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('RCM')">{{moduleName.RCM}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/medical-record']"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.MedicalRecord }"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'Medical Record')"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('MedicalRecord')">{{moduleName.MedicalRecord}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/payment']"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.Payments }"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'Payments')"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle('Payments')">{{moduleName.Payments}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/services/tpn']" routerLinkActive="active-link"
                                    [ngClass]="{'active-link-on-route': this.activeModuleName == moduleName.TPN }"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Services', 'TPN')"
                                    (click)="setTitle('TPN (Third Party Negotiation)')">{{moduleName.TPN}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-Fourth">
                    <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                        data-bs-target="#reportCallapse" aria-expanded="false" aria-controls="reportCallapse">
                        <span class="sidebar-icon" (click)="addBodyClass()"><span
                                class="nav-icon custom-icons icon-dasktop"></span></span> <span
                            class="sidebar-text">Reports</span>
                    </span>
                </h2>
                <div id="reportCallapse" class="accordion-collapse collapse" aria-labelledby="flush-Fourth"
                    data-bs-parent="#sidebarAccordion">
                    <div class="accordion-body">
                        <ul class="sub-nav">
                            <li *ngIf="coreHelperService.getLoggedInUserDomain() === 0"><a href="javascript:;" class="k-link"
                                    [hidden]="CoreRoleAcess.getModulePermission(moduleName.CallRecordAnalytics)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.CallRecordAnalytics)"
                                    [routerLink]="['auth/report/call-record-analytics']" routerLinkActive="active-link"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.CallRecordAnalytics}}</a>
                            </li>
                            <li><a href="javascript:;" class="k-link" [routerLink]="['auth/report/claims-billed']"
                                    routerLinkActive="active-link"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.ClaimsBilled)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.ClaimsBilled}}</a>
                            </li>

                            <li><a class="k-link" [routerLink]="['auth/report/payment-summary']"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="setTitle(moduleName.PaymentSummary)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', 'Payment Summary')">{{moduleName.PaymentSummary}}</a>
                            </li>
                            <li><a href="javascript:;" class="k-link" [routerLink]="['auth/report/payment-detail']"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.PaymentDetail)">{{moduleName.PaymentDetail}}</a>
                            </li>
                            <li><a href="javascript:;" class="k-link" [routerLink]="['auth/report/patient-statement']"
                                    routerLinkActive="active-link"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.PatientStatement)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.PatientStatement}}</a>
                            </li>
                            <li><a href="javascript:;" class="k-link" [routerLink]="['auth/report/aging']"
                                    routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.Aging)"
                                    >{{moduleName.Aging}}</a>
                            </li>
                            <li *ngIf="this.userDomain === this.internalDomainId"><a href="javascript:;" class="k-link" [routerLink]="['auth/report/aging-summary']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                [hidden]="CoreRoleAcess.getModuleNewPermission('Reports', moduleName.AgingSummary)"
                                >{{moduleName.AgingSummary}}</a>
                            </li>
                            <li><a href="javascript:;" class="k-link" [routerLink]="['auth/report/ur-daily']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.UrDaily}}</a>
                            </li>
                            <li ><a href="javascript:;" class="k-link" [routerLink]="['auth/report/ur-review']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.URReview}}</a>
                            </li>
                            <li *ngIf="this.userDomain === this.internalDomainId"><a href="javascript:;" class="k-link" [routerLink]="['auth/report/activity-summary']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.ActivitySummary}}</a>
                            </li>
                            <li *ngIf="this.userDomain === this.internalDomainId"><a href="javascript:;" class="k-link" [routerLink]="['auth/report/payer-priority']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.PayerPriorityBalance}}</a>
                            </li>
                            <li *ngIf="this.userDomain === this.internalDomainId"><a href="javascript:;" class="k-link" [routerLink]="['/auth/report/patient-balance']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.PatientBalance}}</a>
                            </li>
                            <li *ngIf="this.userDomain === this.internalDomainId"><a href="javascript:;" class="k-link" [routerLink]="['/auth/report/payer-type']"
                                routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                                >{{moduleName.PayerTypeBreakdown}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div [hidden]="CoreRoleAcess.getSectionNewPermission('Administration')" class="accordion-item">
                <h2 class="accordion-header" id="flush-fifth">
                    <span class="accordion-button sidebar-link collapsed" data-bs-toggle="collapse"
                        data-bs-target="#adminCallapse" aria-expanded="false" aria-controls="adminCallapse">
                        <span class="sidebar-icon" (click)="addBodyClass()"><span
                                class="nav-icon custom-icons icon-shield"></span></span> <span
                            class="sidebar-text">Administration</span>
                    </span>
                </h2>
                <div id="adminCallapse" class="accordion-collapse collapse" aria-labelledby="flush-fifth"
                    data-bs-parent="#sidebarAccordion">
                    <div class="accordion-body">
                        <ul class="sub-nav">
                            <li [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.Permission)"><a class="k-link" [routerLink]="['auth/administration/permission']"
                                routerLinkActive="active-link" (click)="setTitle('Permission')"
                               >Permission</a>
                        </li>
                            <li><a class="k-link"
                                [routerLink]="['auth/administration/collection-admin']"
                                routerLinkActive="active-link" 
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="setTitle('RCM For Admin')">RCM For Admin</a>
                            </li>
                            <li><a class="k-link"
                                [routerLink]="['auth/administration/collection-goal']"
                                routerLinkActive="active-link" 
                                [routerLinkActiveOptions]="{exact: true}"
                                (click)="setTitle('Collection Goal')">Collection Goal</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/user-management']"
                                    routerLinkActive="active-link" (click)="setTitle('User Management')"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.UserManagement)">{{moduleName.UserManagement}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/announcement']"
                                    routerLinkActive="active-link" (click)="setTitle('Announcement')"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.Announcement)">{{moduleName.Announcement}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/diagnostic']"
                                    routerLinkActive="active-link" (click)="setTitle('Diagnostic Code')"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.DiagnosticCode)">{{moduleName.DiagnosticCode}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/document-workflow']"
                                    routerLinkActive="active-link" (click)="setTitle('Document Workflow')"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.DocumentWorkflow)">{{moduleName.DocumentWorkflow}}</a>
                            </li>
                            <!-- <li><a class="k-link" [routerLink]="['auth/administration/status-workflow']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.StatusFlowMaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.StatusFlowMaster)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.StatusFlowMaster}}</a>
                            </li> -->
                            <li><a class="k-link" [routerLink]="['auth/administration/module']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.Modulemaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.Modulemaster)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.Modulemaster}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/facility-documents']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.FacilityDocument)"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.FacilityDocument)"
                                    >{{moduleName.FacilityDocument}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/service']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.ServiceMaster)"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.ServiceMaster)">{{moduleName.ServiceMaster}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/service-type']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.ServiceTypeMaster)"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.ServiceTypeMaster)">{{moduleName.ServiceTypeMaster}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/taxonomy']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.FacilityIdentifier)"
                                    [routerLinkActiveOptions]="{exact: true}"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.FacilityIdentifier)">{{moduleName.FacilityIdentifier}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/message-configuration']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.MessageConfiguration)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.MessageConfiguration)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.MessageConfiguration}}</a>
                            </li>
                            <li><a class="k-link" [routerLink]="['auth/administration/tpn-master']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.TPNMaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.TPNMaster)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.TPNMaster}}</a></li>
                            <li><a class="k-link" [routerLink]="['auth/administration/tenure-goals']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.TenureGoals)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.TenureGoals)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.TenureGoals}}</a></li>

                            <li><a class="k-link" [routerLink]="['auth/administration/statistics']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.Statistics)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.Statistics)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.Statistics}}</a></li>

                            <li><a class="k-link" [routerLink]="['auth/administration/payment-task']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.PaymentTask)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.PaymentTask)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.PaymentTask}}</a> </li>

                            <li><a class="k-link" [routerLink]="['auth/administration/upload-type-master']"
                                    routerLinkActive="active-link" (click)="setTitle(moduleName.UploadTypeMaster)"
                                    [hidden]="CoreRoleAcess.getModuleNewPermission('Administration', moduleName.UploadTypeMaster)"
                                    [routerLinkActiveOptions]="{exact: true}">{{moduleName.UploadTypeMaster}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div [hidden]="CoreRoleAcess.getSectionNewPermission('Invoicing')" class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                    <span [routerLink]="['auth/invoicing']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}"
                        class="accordion-button sidebar-link statick-link" (click)="setTitle('Invoicing', true)">
                        <span class="sidebar-icon" (click)="addBodyClass()">
                            <span class="nav-icon custom-icons icon-list-lg"></span>
                        </span>
                        <span class="sidebar-text"> Invoicing</span>
                    </span>
                </h2>
            </div>
        </div>
    </div>
    <div class="version-text">
        <p>{{versionText}}</p>
    </div>
</aside>

<!-- Overlay Responsive -->
<div class="responsive-overlay" (click)="addBodyClass()"></div>