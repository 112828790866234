import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-letterhead',
  templateUrl: './letterhead.component.html',
  styleUrls: ['./letterhead.component.scss']
})
export class LetterHeadComponent implements OnInit {
  ngOnInit(): void {
    
  }
  
}
