import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'localDateTime',
})

export class LocalDateTimePipe implements PipeTransform {
  transform(date: Date = new Date()): string {
    let localDate = new Date(date); // val is in UTC
    let localOffset = localDate.getTimezoneOffset() * 60000;
    let localTime = localDate.getTime() - localOffset;

    localDate.setTime(localTime);
    return formatDate(localDate, 'MM/dd/yyyy h:mm a', 'en-US');
  }
}

@Pipe({
  name: 'localDate',
})

export class LocalDatePipe implements PipeTransform {
  transform(date: Date = new Date()): string {
    return formatDate(
      new Date(new Date(date).getTime() +
        new Date(date.toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone })).getTime() -
        new Date(date.toLocaleString('en-US', { timeZone: 'UTC' })).getTime()), 'MM/dd/yyyy', 'en-US');
  }
}