import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { IdNameModel } from './topativity.classes';
import * as _ from 'lodash';
@Injectable({ providedIn: 'root' })

export class TopActivityService {

    topActivityList: IdNameModel[] = [];

    constructor(public cookieService: CookieService, public coreHelperService: CoreHelperService) { }

    getActivity() {
        let activity = this.cookieService.get('_activity');
        if (!this.coreHelperService.isStringEmptyOrWhitespace(activity)) {
            this.topActivityList = JSON.parse(activity);
            this.topActivityList = this.topActivityList.reverse();
            const activityUnique = [...new Set(this.topActivityList.map(item => item.name))];
            return activityUnique;
        }
        else {
            return null;
        }
    }

    setActivity(name: string) {
        let activityData = this.cookieService.get('_activity');
        if (!this.coreHelperService.isStringEmptyOrWhitespace(activityData)) {
            this.topActivityList = JSON.parse(activityData);
        }
        if (this.coreHelperService.isNullOrUndefined(this.topActivityList)) {
            this.topActivityList = new Array<IdNameModel>();
        }

        let idName = new IdNameModel();
        idName.id = this.coreHelperService.isArrayEmpty(this.topActivityList) ? 0 : this.topActivityList.length + 1;
        idName.name = name;

        this.topActivityList.push(idName);
        this.cookieService.set('_activity', JSON.stringify(this.topActivityList));
    }

}

export enum Activities {
    ProfileUpdated = "Profile Updated",
    FacilityUpdated = "Facility Updated",
    FacilityAdded = "Facility Added",
    FacilityGroupingUpdated = "FacilityGrouping Updated",
    FacilityGroupingAdded = "FacilityGrouping Added",
    CarrierUpdated = "Carrier Updated",
    CarrierAdded = "Carrier Added",
    PatientUpdated = "Patient Updated",
    PatientAdded = "New Patient Added",
    //Census = "census", -- pending
    DocumentUpdated = "Document Updated",
    //VOB = "VOB", -- pending
    AuthorisationUpdated = "Authorisation Updated",
    //billing = "Biliing" --pending
    //collection
    //payment
    //TPN
    //Refund
    //Report Modules
    UserUpdated = "User Updated",
    UserAdded = "New user added",
    DepartmentUpdated = "Department Updated",
    DepartmentAdded = "Department Added",
    RoleManagementUpdated = "RoleManagement Updated",
    RoleManagementAdded = "RoleManagement Added",
    TeamUpdated = "Team Updated",
    TeamAdded = "Team Added",
    AnnouncementUpdated = "Announcement Updated",
    AnnouncementAdded = "Announcement Added",
    DiagnosticUpdated = "Diagnostic Updated",
    DiagnosticAdded = "Diagnostic Added",
    DocumentTypeUpdated = "DocumentType Updated",
    DocumentTypeAdded = "DocumentType Added",
    ModuleMasterUpdated = "ModuleMaster Updated",
    ModuleMasterAdded = "ModuleMaster Added",
    statusWorkflowUpdated = "statusWorkflow Updated",
    statusWorkflowAdded = "statusWorkflow Added",
    FacilityDocumentUpdated = "FacilityDocument Updated",
    FacilityDocumentAdded = "FacilityDocument Added",
    ServiceMasterUpdated = "ServiceMaster Updated",
    ServiceMasterAdded = "ServiceMaster Added",
    ServiceTypeMasterUpdated = "ServiceTypeMaster Updated",
    ServiceTypeMasterAdded = "ServiceTypeMaster Added",
    TaxonomyUpdated = "Taxonomy Updated",
    TaxonomyAdded = "Taxonomy Added",
    TpnMasterUpdated = "TpnMaster Updated",
    TpnMasterAdded = "TpnMaster Added",
    PaymentTaskUpdated = "PaymentTask Updated",
    PaymentTaskAdded = "PaymentTask Added",
}


