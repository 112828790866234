import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-bar-chart',
  templateUrl: './single-bar-chart.component.html',
  styleUrls: ['./single-bar-chart.component.scss']
})
export class SingleBarChartComponent implements OnInit {

  @Input() labels:any={ 
    show: true,
    labelText:["Current Collected", "Anticipated"],
    color: '#122B45', 
    font: '14px Poppins, sans-serif'
  };
  @Input() currency:any = false;
  @Input() buttons:any={
    show: false,
    buttonLabel: "button",
    clickCallBack: null,
    componentRef:null
  }
  @Input() fieldKey: string;
  @Input() fieldName : string;  

  chartData:any=[];

  private _childData: any;
  @Input()
  set data(parentData: any) {
      // every time the data from the parent changes this will run
      this._childData = parentData; // ...or do some other crazy stuff
      parentData != null &&  this.convertObjectToMapSvg(parentData);
  }
  get data(): any { return this._childData; }

  constructor() { }

  ngOnInit(): void {
  }

  convertObjectToMapSvg(data:any){
    this.chartData = data.map((obj:any) => {
      return {
        key: obj[this.fieldKey],
        value: obj[this.fieldName]
      };
    });
  }

  buttonClick(){
    return this.buttons.clickCallBack();
  }
}

