import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderService } from './providers/core-interceptor/loader.service';
import { InterceptorService } from './providers/core-interceptor/core-interceptor.service';

// Shared
import { SharedModule } from './shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

//Okta Configuration
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import oktaConfig from './app.config';
import { NgIdleModule } from '@ng-idle/core';
import { HistoryComponent } from './common/history/history.component';
import { GaugesModule } from '@progress/kendo-angular-gauges';


const oktaAuth = new OktaAuth(oktaConfig.oidc);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    OktaAuthModule,
    NgIdleModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    GaugesModule
  ],
  providers: [LoaderService,
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true
    },
    { provide: OKTA_CONFIG, useValue: { oktaAuth }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
