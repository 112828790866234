import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreHttpService {

  constructor(private http: HttpClient,
    private router: Router,
    // public toastrService: ToastrService,
    public spinner: NgxSpinnerService) {
  }

  protected get<T>(method: string, params?: any): Observable<any> {
    return this.http.get<T>(method, {
      headers: this.setHeaders(),
      params: this.setParams(params)
    }).pipe(
      map((response) => this.displayMessages(response)),
      catchError(err => this.handleError(err))
    );
  }

  protected post<T>(method: string, body: any): Observable<any> {
    const model = JSON.stringify(body);
    return this.http.post<T>(method, model, { headers: this.setHeaders() }).pipe(
      map(response => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  protected put<T>(method: string, body: any): Observable<any> {
    const model = JSON.stringify(body);
    return this.http.put<T>(method, model, { headers: this.setHeaders() }).pipe(
      map((response) => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  protected delete<T>(method: string, params?: any): Observable<any> {
    return this.http.delete<T>(method, { headers: this.setHeaders(), params: this.setParams(params) }).pipe(
      map((response) => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  protected postFormData(method: string, body: any): Observable<any> {
    return this.http.post(method, body, { headers: this.setHeaders('formData') }).pipe(
      map((response) => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  protected postParams<T>(method: string, body: any, params?: any): Observable<any> {
    return this.http.post<T>(method, "", {  params: params, headers: this.setHeaders() }).pipe(
      map(response => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  /**
   * set appropriate headers and return is
   * @param contentType optional content type header value, default is application/json
   * @returns HttpHeaders with Authorization and ContentType headers set
   */
  private setHeaders(contentType?: string) {
    let headers = new HttpHeaders();

    if (contentType === 'formData') {

    }
    else if (contentType) {
      headers = headers.set('Content-Type', contentType);
    }
    else {
      headers = headers.set('Content-Type', 'application/json');
    }

    const token = localStorage.getItem('token');

    if (token && token != "undefined") {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    return headers;
  }

  /**
   * set specified params list to HttpParams object
   * @param params list of parameters to set
   * @returns HttpParams object with parameters set
   */
  private setParams(params: any) {
    let httpParams = new HttpParams();

    if (!params) {
      return httpParams;
    }

    for (const param of Object.keys(params)) {
      if (params[param]) {
        httpParams = httpParams.set(param, params[param]);
      }
    }

    return httpParams;
  }

  /**
   * display info toastr for each message in response object
   * @param response Api response
   * @returns response as it is
   */
  private displayMessages(response: any) {
    if (response && response.messages && response.messages.length > 0) {
      response.messages.forEach((message: any) => {
        //this.toastrService.info(message.messageText, message.title, { timeOut: 7000 });
      });
    }

    return response;
  }

  /**
   * common error handler
   * 1. for unauthorized error redirect to login page
   * 2. for other errors displat error toastr for each error messages
   * 3. for unknown errro display generic error message
   * @param response Api response
   * @returns observable of null
   */
  private handleError(response: Response | any) {
    let error: any = response.error;

    // in unauthorized or token expiry case redirect to login
    if ((response.status == 401 || response.status == 403)) {
      // this.toastrService.warning("You are not authorized to access this route");
      localStorage.clear();

      this.router.navigate(['/auth/login']).then();
    }
    else if (error && error.errors && error.errors.length > 0) {
      error.errors.forEach((err: any) => {
        // this.toastrService.error(err.errorMessage, err.key == '' ? 'Error' : err.key, { timeOut: 7000 });
      });
    }
    else {
      // this.toastrService.error('Oops...Something went wrong, please try again or contact support', 'Error', { timeOut: 5000 });
    }

    return of(null);
  }
  protected getBlobData<T>(method: string): Observable<any> {
    return this.http.get(method, { headers: this.setHeaders('formData'), responseType: 'blob' }).pipe(
      map((response) => { return response; })
      , catchError(err => this.handleError(err)));
  }


  /* Okta Integration method */
  protected postOkta<T>(method: string, body: any): Observable<any> {
    const model = JSON.stringify(body);
    return this.http.post<T>(method, model, { headers: this.setOktaHeaders() }).pipe(
      map(response => this.displayMessages(response)),
      catchError(err => this.handleError(err)));
  }

  private setOktaHeaders(contentType?: string) {
    let headers = new HttpHeaders();
    if (contentType === 'formData') {
    }
    else if (contentType) {
      headers = headers.set('Content-Type', contentType);
    }
    else {
      headers = headers.set('Content-Type', 'application/json');
    }
    //const token = localStorage.getItem('token');

    //Okta Integration
    let token = environment.oktaApiToken;
    if (token && token != "undefined") {
      headers = headers.append('Authorization', 'SSWS ' + token);
    }
    return headers;
  }
}
