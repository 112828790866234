export class ConstantKey {
    public static readonly STRINGLIMITSHORT = 25;
    public static readonly STRINGLIMITLONG = 30;
}


export class LoginServices {
    loginPin: string;
    pinUserId: string;
    pinUserName: string;
    pinUserPswd: string;
    constructor({
        loginPin,
        pinUserId,
        pinUserName,
        pinUserPswd
    } = {} as Partial<LoginServices>) {
        this.loginPin = loginPin;
        this.pinUserId = pinUserId;
        this.pinUserName = pinUserName;
        this.pinUserPswd = pinUserPswd;
    };
}